export const gFetch = {
  newStrapi: {
    url: 'https://cnt.rhelpers.com',
    photoUrl: 'https://cnt.rhelpers.com',
    token:
        'Bearer 5c5a0b31adbada4b61179ec1e288ec503339250be498a2ddf42692a9e8bd4cd45fe40682334a2d2032e9d466159d1c762434259f8685745f1354c0f9a213bdc8360a250dd8d413798a6b60e87564667d4750752897fde149634f7a9745683ec9371bfef21d13af2d94282eeea1fde21ca10f0461b628eb507219daa05073b147',
    populate: 'deep',
    path: function (url, params) {
      return `${this.url}${url}${params ? `?${new URLSearchParams(params)}` : ''}`;
    },
  },
};

export async function get_strapi(url, options) {
  const updatedOptions = options?.next || options?.cache ? options : { next: { revalidate: 10 }, ...options };
  try {
    const response = await fetch(url, updatedOptions);
    if (response.ok) {
      let json = await response.json();
      return json.data;
    } else {
      console.log('▓▓▓▓▓ Strapi HTTP ERROR => ', response);
    }
  } catch (error) {
    console.error(error);
  }
}

export const qlQueries = {

  getCase: {
    query: `query
        {case (id: 1) {data{attributes{case_featured_img{data{attributes{name}}}}}}}`,
  },

  getPositionVideo(position) {
    return {
      query: `query {
        positions(filters:  { slug: { eq: "${position}" } }) {
          data {
            attributes {
              video
            }
          }
        }
      }`,
    };
  },
  getDepartmentVideo(department) {
    return {
      query: `query {
        departments(filters:  { slug: { eq: "${department}" } }) {
          data {
            attributes {
              video
            }
          }
        }
      }`,
    };
  },
  getCases(empID) {
    return {
      query: `
      query {
        cases (filters: {
          pinned_persons: {
            person_id: {
              eq: "${empID}"
            }
          }
        }
        pagination: {
          limit: -1
        }
        ) {
          data{
            id
            attributes {
            title
              featured_img {
                data{
                  id
                  attributes{
                  alternativeText
                  name
                  url
                  }
                }
              }
            }
          }
        }
      }
            `,
    };
  },
  getSingleCase(slug) {
    return {
      query: `
        query {
          cases (filters:{
            slug: {
              eq: "${slug}"
            }
          }){
            data{
              attributes {
              title
              slug
              case_body
              Tags{
                data{
                  id
                  attributes{
                    name
                  }
                }
              }
              pinned_persons {
                  name
                  id
                  person_id
                }
                Included_in_positions {
                  data{
                    id
                    attributes{
                      name
                      slug
                      crm_id
                    }
                  }
                }
                  Included_in_departments {
                    data{
                      id
                      attributes {
                        name
                        slug
                        crm_id
                      }
                    }
                    
                  }
                featured_img {
                  data{
                    id
                    attributes{
                    alternativeText
                    name
                    url
                    }
                  }
                }
              }
            }
          }
        }
      `,
    };
  },
};
