// Returns max rate for RateSlider
export function getMaxRate(cards) {
  let value = 0;
  for (let card of cards) {
    if (!card.sum_rate_rh) continue;
    const nextValue = +card.sum_rate_rh;
    if (nextValue > value) value = nextValue;
    if (value >= 1) break;
  }
  if (value < 0.5) return 0;
  if (value >= 0.5 && value < 1) return 1;
  if (value >= 1) return 2;
}

export function parseRGBA(rgbaColor) {
  // Видаляємо "rgba(" та ")" і отримуємо "0,0,0,.15"
  const colorValues = rgbaColor.substring(rgbaColor.indexOf('(') + 1, rgbaColor.lastIndexOf(')')).split(', ' || ',');

  // Перетворюємо значення в числа
  const r = parseInt(colorValues[0], 10);
  const g = parseInt(colorValues[1], 10);
  const b = parseInt(colorValues[2], 10);
  const a = parseFloat(colorValues[3]);

  return { r, g, b, a };
}

export function getContrastColor(color) {
  let r, g, b;

  if (color.startsWith('#')) {
    // Видаляємо символ `#` і отримуємо три компоненти кольору
    const hex = color.slice(1);
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else if (color.startsWith('rgb')) {
    // Видобуваємо компоненти з формату rgba
    const rgbaValues = parseRGBA(color);
    r = rgbaValues.r;
    g = rgbaValues.g;
    b = rgbaValues.b;
  } else {
    // Інші формати кольорів не підтримуються
    throw new Error('Unsupported color format');
  }

  // Формула для визначення контрастного кольору
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness >= 128 ? '#5a5a5a' : '#FFFFFF';
}

const defaultVideoSrc = "https://youtu.be/H5lgWUPpU8Y?si=Dn2GMnWiscW73haR";

export function getVideoSrc(posVideo, depVideo) {
  const posVideoSrc = posVideo?.positions?.data ? posVideo?.positions?.data[0]?.attributes?.video : null;
  const depVideoSrc = depVideo?.departments?.data ? depVideo?.departments?.data[0]?.attributes?.video : null;
  return posVideoSrc ?? depVideoSrc ?? defaultVideoSrc;
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      backgroundColor: stringToColor(name.toUpperCase()),
      // color: getContrastColor(stringToColor(name)),
      color: 'white',
    },
    // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    children: `${name.toUpperCase()[0]}`,
  };
}
