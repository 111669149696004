import { configureStore } from "@reduxjs/toolkit";
import filterSlice from "./slices/filterSlice";
import cartSlice from "./slices/cartSlice";
import settingsSlice from "./slices/settingsSlice";
import notificationSlice from "./slices/notificationSlice";

export const store = configureStore({
  reducer: {
    filterSlice,
    cartSlice,
    settingsSlice,
    notificationSlice,
  },
});
