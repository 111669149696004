import { Divider, IconButton, SwipeableDrawer, Typography } from '@mui/material';
import React from 'react';
import { AffiliateForm } from "../AffiliateForm/AffiliateForm";
import AffiliateInput from "../AffiliateInput/AffiliateInput";
import CloseIcon from '@mui/icons-material/Close';

export default function FormDrawer({ view, formOpen, setFormOpen, setDemoMode, setShowInfo }) {
  return (
    <>
      <SwipeableDrawer
        disableScrollLock={false}
        anchor="left"
        open={formOpen}
        onClose={() => setFormOpen(false)}
        onOpen={() => setFormOpen(true)}
        disableDiscovery
        disableBackdropTransition
        swipeAreaWidth={0}
        PaperProps={{
          sx: {
            padding: '65px 1rem 38px',
            width: !view ? '100%' : '403px',
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column nowrap',
            minWidth: '16rem',
            borderRadius: '0',
            zIndex: 11,
            '& > :nth-of-type(n)': {
              maxWidth: '500px',
            }
          },
        }}
      >
        <Typography
          variant={'subtitle2'}
          textAlign={'center'}
          gutterBottom
          sx={{
            textWrap: 'balance',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '21.34px',
            color: '#292929',
          }}
        >
          {`Do you have an affiliate code?`}
        </Typography>
        <AffiliateInput setDemoMode={setDemoMode} setFormOpen={setFormOpen} setShowInfo={setShowInfo} />
        <Divider sx={{ padding: '29.5px 0', fontWeight: 'bold', width: '100%' }}>OR</Divider>
        <Typography
          variant={'subtitle2'}
          textAlign={'center'}
          sx={{
            textWrap: 'balance',
            marginBottom: '35px',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '21.34px',
            color: '#292929',
          }}
        >
          {`Just fill out the form below and we'll help you to become one of our affiliates. 😉`}
        </Typography>
        <AffiliateForm />
      </SwipeableDrawer>

      <IconButton
        size="large"
        onClick={() => {
          setFormOpen(false);
        }}
        sx={{
          position: view ? 'absolute' : 'fixed',
          top: '13px',
          left: !view ? '' : formOpen ? '403px' : '-100%',
          right: view ? '' : formOpen ? '0' : '100%',
          transform: 'translateX(-50%)',
          zIndex: 1202,
          backgroundColor: '#1677FF',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: 'rgba(96, 165, 250)',
          },
        }}
      >
        <CloseIcon sx={{ color: 'white' }} fontSize={'small'} />
      </IconButton>
    </>
  );
}
