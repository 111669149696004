import React, { useState } from 'react';
import { Box, Divider, Tab } from "@mui/material";
import ColorWithTransparency from "../ColorWithTransparency";
import { editCard, editFooter, editMain } from "../../../../redux/slices/settingsSlice";
import BorderRadiusInput from "../BorderRadiusInput/BorderRadiusInput";
import { useSelector } from "react-redux";
import { SettingsFilter } from "../SettingsFilter";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const SettingsTabs = ({ isDemoMode }) => {
  const [value, setValue] = useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    departments,
    filteredPositions,
    filteredLangs,
  } = useSelector(state => state.filterSlice);

  const {
    backgroundColor,
    primaryColor,
    secondaryColor,
  } = useSelector(state => state.settingsSlice.settings.style.main);

  const {
    avatarBackground,
    cvAvatarBackground,
    chipBorderColor,
    chipTextColor
  } = useSelector(state => state.settingsSlice.settings.style.card);

  const {
    filterBtnBGColor
  } = useSelector(state => state.settingsSlice.settings.style.footer);

  const affiliateCode = localStorage.getItem('affiliateCode') || ''

  const { department, position } = useSelector(state => state.settingsSlice.settings.filter);


  return (
      <Box sx={{ width: '100%', mb: '35px' }}>
        <TabContext value={value}>
          <Box>
            <TabList
                onChange={handleChange}
                aria-label="settings tabs"
                variant="fullWidth"
            >
              <Tab label="STYLES" value='0'/>
              <Tab label="FILTERS" value='1' disabled={!affiliateCode}/>
            </TabList>
          </Box>
          <TabPanel value='0'>
            <Divider sx={{
              padding: '16px 0',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.01px',
              color: '#292929',
              }}>Main</Divider>
            <Box sx={{
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
              '@media (max-width: 700px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
              '@media (max-width: 440px)': {
                gridTemplateColumns: '1fr',
              },
              gap: '11px 16px',
              width: '100%'
            }}>
              <ColorWithTransparency
                  color={backgroundColor}
                  name={'backgroundColor'}
                  label="BG Color"
                  editFunc={editMain}
                  disabled={!isDemoMode}
              />
              <ColorWithTransparency
                  color={primaryColor}
                  name={'primaryColor'}
                  label="Primary Color"
                  editFunc={editMain}
                  disabled={!isDemoMode}
              />
              <ColorWithTransparency
                  color={secondaryColor}
                  name={'secondaryColor'}
                  label="Secondary Color"
                  editFunc={editMain}
                  disabled={!isDemoMode}
              />
              <BorderRadiusInput disabled={!isDemoMode}/>
            </Box>
            <Divider sx={{
              padding: '16px 0',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.01px',
              color: '#292929',
              }}>Card</Divider>
            <Box sx={{
              display: 'grid',
              alignItems: 'end',
              gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
              '@media (max-width: 700px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
              '@media (max-width: 440px)': {
                gridTemplateColumns: '1fr',
              },
              gap: '10px',
              width: '100%'
            }}>
              <ColorWithTransparency
                  color={avatarBackground}
                  name={'avatarBackground'}
                  label="Avatar BG Color"
                  editFunc={editCard}
                  disabled={!isDemoMode}
              />
              <ColorWithTransparency
                  color={cvAvatarBackground}
                  name={'cvAvatarBackground'}
                  label="CV Avatar BG Color"
                  editFunc={editCard}
                  disabled={!isDemoMode}
              />
              <ColorWithTransparency
                  color={chipBorderColor}
                  name={'chipBorderColor'}
                  label="Chip Border Color"
                  editFunc={editCard}
                  disabled={!isDemoMode}
              />
              <ColorWithTransparency
                  color={chipTextColor}
                  name={'chipTextColor'}
                  label="Chip Text Color"
                  editFunc={editCard}
                  disabled={!isDemoMode}
              />
            </Box>
            <Divider sx={{
              padding: '16px 0',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '24.01px',
              color: '#292929',
              }}>Footer</Divider>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              width: '100%'
            }}>
              <ColorWithTransparency
                  color={filterBtnBGColor}
                  name={'filterBtnBGColor'}
                  label="Filter Button Color"
                  editFunc={editFooter}
                  disabled={!isDemoMode}
              />
            </Box>
          </TabPanel>
          <TabPanel value='1'>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
              width: '100%',
              padding: '43px 0 35px'
            }}>
              <SettingsFilter
                  placeholder={"Employee category"}
                  values={departments}
                  id="deps"
                  disabled={department.length === 0 && position.length > 0}
                  filterName='department'
              />
              <SettingsFilter
                  placeholder={"Employee positions"}
                  values={filteredPositions}
                  id="pos"
                  filterName='position'
              />
              <SettingsFilter
                  placeholder={"Employee languages"}
                  values={filteredLangs}
                  id="langs"
                  filterName='language'/>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
  );
};

export default SettingsTabs;
