import { useState } from "react";
import { Backdrop, Box, Button, Fade, IconButton, Modal, Step, StepLabel, Stepper, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from "@mui/icons-material/Close";
import TabImg1 from './assets/tab-1.png';
import TabImg1D from './assets/tab-1-desc.png';
import TabImg2 from './assets/tab-2.png';
import TabImg2D from './assets/tab-2-desc.png';
import TabImg3 from './assets/tab-3.png';
import TabImg3D from './assets/tab-3-desc.png';
import Arrow from './assets/silver.svg';

const steps = [
  {
    id: 0,
    label: 'This is the settings panel where you can change your module',
    img: TabImg1,
    imgD: TabImg1D
  },
  {
    id: 1,
    label: 'This is what your final module will look like',
    img: TabImg2,
    imgD: TabImg2D
  },
  {
    id: 2,
    label: 'The end result is that you get a module without a settings panel',
    img: TabImg3,
    imgD: TabImg3D
  },
];

export default function InfoModal({ showInfo, setShowInfo, view }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === 2) {
      setShowInfo(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      key={view}
      open={showInfo}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      disableScrollLock={view}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={showInfo}>
        <Box
          sx={{
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            '&:focus': {
              outline: 'none'
            },
            boxShadow: 24,
            overflowY: 'auto',
            p: '71px 78px 72px',
            borderRadius: '8px',
            maxHeight: '90vh',
            maxWidth: '1122px',
            width: '100%',
            '@media (max-width: 1200px)': {
              maxWidth: '90%',
              p: '28px 20px',
            },
            '@media (max-width: 863px)': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '450px',
              height: '631px',
              p: '62px 39px 32px',
              overflowX: 'hidden',
            },
          }}
        >
          {!view ? (
            <>
              <Box textAlign={'center'} sx={{ alignSelf: 'end', color: "#D6D6D6", mb: '13px', position: 'absolute', top: '24px', right: '24px' }}>
                <Typography component={'span'} variant={'body1'} sx={{ color: "#1677FF" }}>{activeStep + 1}</Typography>
                <Typography component={'span'} variant={'body1'}>/{steps.length}</Typography>
              </Box>
              {activeStep !== steps.length - 1 ? (
                <>
                  <Typography variant={'subtitle1'} textAlign={'center'} sx={{
                    fontSize: '18px',
                    lineHeight: '24.51px',
                    color: '#282828',
                    mb: '22px',
                  }}>{steps[activeStep].label}</Typography>
                  <img src={steps[activeStep].img} alt={`Tab img`} style={{ flex: 1 }} />
                </>
              ) : (
                <>
                  {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', backgroundColor: 'rgba(96,165,250,0.05)', borderRadius: '50%', mb: '10px', p: '5px' }}>
                    <InfoOutlinedIcon color={'primary'} sx={{ width: '30px', height: '30px', p: '3px', backgroundColor: 'rgba(96,165,250,0.1)', borderRadius: '50%', }} />
                  </Box> */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '79px', height: '79px', backgroundColor: 'rgba(96,165,250,0.05)', borderRadius: '50%', mb: '18px', p: '10px' }}>
                    <InfoOutlinedIcon sx={{ width: '59px', height: '59px', p: '10px', backgroundColor: 'rgba(96,165,250,0.1)', borderRadius: '50%', color: '#1677FF' }} fontSize={'large'} />
                  </Box>
                  <Typography variant={'subtitle1'} textAlign={'center'} sx={{ mb: '22px' }}>{steps[activeStep].label}</Typography>
                  <img src={steps[activeStep].img} alt={`Tab img`} />
                </>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, gap: '16px', width: '100%' }}>
                {activeStep !== 0 && (
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{
                      width: '100%',
                      height: '60px',
                      border: '1px solid rgba(22, 119, 255, 0.5)',
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: '24.51px',
                      backgroundColor: 'white',
                      color: '#1677FF',
                      '&:hover': {
                        backgroundColor: 'rgba(22, 119, 255, 0.04)',
                        border: '1px solid #1677FF',
                      },
                    }}
                    size="large"
                  >
                    BACK
                  </Button>
                )}

                <Button
                  variant="contained"
                  size="large"
                  onClick={handleNext}
                  sx={{
                    width: '100%',
                    height: '60px',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '24.51px',
                    backgroundColor: '#1677FF',
                    '&:hover': {
                      backgroundColor: 'rgb(15, 83, 178)',
                      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                    },
                  }}>
                  {activeStep === steps.length - 1 ? 'OK' : 'NEXT'}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <IconButton
                aria-label='close'
                onClick={() => setShowInfo(false)}
                sx={{
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                  zIndex: 10,
                  '@media (max-width: 1200px)': {
                    top: '10px',
                    right: '10px',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <Stepper
                alternativeLabel
                sx={{
                  // display: 'flex',
                  // alignItems: 'stretch',
                  // flexGrow: 1,
                  display: 'grid',
                  // gridTemplateColumns: 'repeat(3, minmax(256px, 1fr))',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '66px',
                  width: '100%',
                  '@media (max-width: 1200px)': {
                    gap: '20px',
                  },
                }}
              >
                {steps.map(({ id, label, imgD }) => (
                  <Step key={id} active sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // flex: 1,
                    // flexGrow: 1,
                    color: '#1677FF',
                    height: '100%',
                    // minHeight: '464px',
                    gap: '54px',
                    '& .MuiStepConnector-root': {
                      left: 'calc(-50% - 46px)',
                    },
                    '& .MuiStepConnector-line': {
                      borderTopStyle: 'dashed',
                      borderTopWidth: '2px',
                    },
                    // width: '256px',
                    // minWidth: '180px',
                    '@media (max-width: 1200px)': {
                      gap: '34px',
                      '& .MuiStepConnector-root': {
                        left: 'calc(-50% - 4px)',
                      },
                    },
                  }}>
                    <StepLabel sx={{ fontSize: '14px', lineHeight: '19.07px' }}>{label}</StepLabel>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        flexGrow: 1,
                        width: '100%',
                        '&::before': {
                          content: '""',
                          display: 'block',
                          backgroundImage: `url(${Arrow})`,
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          width: '70px',
                          height: '50px',
                          position: 'absolute',
                          transform: id === 0 ? 'translate(-10%, 100%) rotate(0deg)' : id === 1 ? 'translate(-100%, -100%) rotate(300deg)' : 'scale(-1, 1) translate(30%, 50%) rotate(120deg)',
                          top: id !== 1 && '0',
                          bottom: id === 1 && '0',
                          left: '0',
                        }
                      }}
                    >
                      <Typography><img src={imgD} alt={`Tab ${id}`} style={{ maxWidth: '100%' }} /></Typography>
                    </Box>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}