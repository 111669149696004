import { useEffect, useState } from 'react';
import {
  ButtonGroup,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { get_strapi, gFetch } from "../../../lib/fetch";
import CloseIcon from "@mui/icons-material/Close";
import TinyRender from "../../TinyRender";

export function Case({ ind, cases }) {
  const [caseInd, setCaseInd] = useState(ind);
  const [defEmpCase, setDefEmpCase] = useState(null);
  const [empCase, setEmpCase] = useState(null);
  const [openCase, setOpenCase] = useState(false);
  const defCaseImgUrl = defEmpCase?.featured_img?.data?.attributes?.url;

  useEffect(() => {
    get_strapi(`${gFetch.newStrapi.url}/api/cases?populate=deep&filters[id][$contains]=${cases[caseInd].id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: gFetch.newStrapi.token,
      },
    }).then((response) => {
      if (response) {
        if (!defEmpCase) setDefEmpCase(response[0].attributes);
        setEmpCase(response[0].attributes);
      } else {
        console.error(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseInd]);

  const handleCloseCase = () => {
    setOpenCase(false);
    setCaseInd(ind);
  };

  // console.log('empCase?.case_body', empCase?.case_body)

  // const iframeRegex = /<iframe.*?<\/iframe>/i;
  // const containsIframe = empCase?.case_body && iframeRegex.test(empCase?.case_body);
  const getHtmlStringModified = (htmlString) => {
    if (!htmlString) {
      return null;
    }

    // modifies width, height and aspect-ratio styles and attributes that are coming from strapi
    let modifiedHtml = htmlString.replace(/width="(\d+)"/i, 'width="100%"');

    if (!modifiedHtml.includes('width=')) {
      if (!modifiedHtml.includes('width:')) {
          modifiedHtml = modifiedHtml.replace(/<iframe/i, '<iframe style="width: 100%;"');
      } else {
          modifiedHtml = modifiedHtml.replace(/(style="[^"]*)width:\s*\d+px;?([^"]*")/i, '$1width: 100%;$2');
      }
    }

    if (!modifiedHtml.includes('style=')) {
      modifiedHtml = modifiedHtml.replace('<iframe', '<iframe style="aspect-ratio: 16/9;"');
    } else {
        if (!modifiedHtml.includes('aspect-ratio:')) {
            modifiedHtml = modifiedHtml.replace('<iframe style="', '<iframe style="aspect-ratio: 16/9; ');
        } else {
            modifiedHtml = modifiedHtml.replace(/(style="[^"]*)aspect-ratio:\s*[^;]+;?([^"]*")/i, '$1aspect-ratio: 16/9;$2');
        }
    }

    modifiedHtml = modifiedHtml.replace(/height="(\d+(?:px)?)"/i, '');
    modifiedHtml = modifiedHtml.replace(/(style="[^"]*)height:\s*\d+px;?([^"]*")/i, '$1$2');

    // modifies <ul> tags that are coming from strapi
    modifiedHtml = modifiedHtml.replace(/<ul\s*(.*?)>/g, (_, attributes) => {
      if (attributes && attributes.includes('style=')) {
          return `<ol ${attributes}>`;
      } else {
          return `<ol ${attributes ? attributes + ' ' : ''}style="list-style-type: disc;">`;
      }
    }).replace(/<\/ul>/g, '</ol>');

    // modifies <a> tags that are coming from strapi
    modifiedHtml = modifiedHtml.replace(/<a\s*(.*?)>/g, (_, attributes) => {
      if (attributes && attributes.includes('style=')) {
          if (!attributes.includes('text-decoration: underline;')) {
              return `<a ${attributes} style="text-decoration: underline;">`;
          }
      } else {
          return `<a ${attributes ? attributes + ' ' : ''}style="text-decoration: underline;">`;
      }
    });

    return modifiedHtml;
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: 'max-content',
          // flexGrow: 1,
          flex: '1 1 26%',
          minWidth: '10rem',
          border: `1px solid ${grey[300]}`,
        }}
      >
        <CardActionArea onClick={() => setOpenCase(true)}>
          <CardMedia
            image={`${gFetch.newStrapi.url}${defCaseImgUrl}`}
            sx={{
              // height: '15dvh',
              aspectRatio: '16/9',
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <CardContent
            sx={{
              padding: '.5rem 1rem',
            }}
          >
            <Typography
              component='h4'
              lineHeight={'1.3'}
              sx={{
                fontVariationSettings: `'wdth' 80`,
                fontWeight: '600',
              }}
            >
              {defEmpCase?.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Dialog
        fullWidth
        open={openCase}
        disableScrollLock={true}
        onClose={handleCloseCase}
        aria-labelledby='customized-dialog-title'
        scroll='paper'
        maxWidth={'xl'}
        sx={{
          padding: '3rem 0',
          // maxWidth: '1900px',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <DialogTitle
          sx={{ padding: '.5rem 1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          onClose={handleCloseCase}
        >
          {empCase?.title}
          <IconButton
            aria-label='close'
            onClick={handleCloseCase}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: '1rem' }}>
          {empCase?.case_body && (
            <TinyRender
              htmlString={getHtmlStringModified(empCase?.case_body)}
              sx={{ width: '100%', borderRadius: '.5rem', overflow: 'hidden' }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <ButtonGroup disableElevation variant='contained' aria-label='Disabled elevation buttons'>
            <Button onClick={() => setCaseInd((prev) => prev - 1)} disabled={caseInd <= 0}>
              Prev case
            </Button>
            <Button onClick={() => setCaseInd((prev) => prev + 1)} disabled={caseInd >= cases.length - 1}>
              Next case
            </Button>
          </ButtonGroup>
          <Button variant='contained' autoFocus onClick={handleCloseCase}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
