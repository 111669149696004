import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Link, Stack, TextField, Typography, } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { MuiTelInput } from 'mui-tel-input';
import { isFormValid, validateOrderData } from "../../../../lib/validateData";
import { getCountryByIP } from "../../../BookingForm/BookingForm";
import { openNotification } from "../../../../redux/slices/notificationSlice";
import { TEST_URL, preferredCountries, globalCompanyName, projectCompany, type } from "../../../../constants";

export const AffiliateForm = () => {
  const dispatch = useDispatch();
  const [customersName, setCustomersName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [userLocationData, setUserLocationData] = useState({ country_code: 'DE', timezone: 'Europe/Berlin' });
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
    checked: '',
  });

  const settingsState = useSelector((state) => state.settingsSlice);

  const handleChangePhone = (newPhone) => {
    const validationResult = validateOrderData('phone', newPhone);
    setErrors((prev) => ({ ...prev, phone: validationResult ?? '' }));
    setPhone(newPhone);
  };

  const handleChangeCheckbox = () => {
    setErrors((prev) => ({ ...prev, checked: '' }));
    setChecked(!checked);
  };

  const handleChange = ({ target }) => {
    const validationResult = validateOrderData(target.name, target.value);
    setErrors((prev) => ({ ...prev, [target.name]: validationResult ?? null }));
    switch (target.name) {
      case 'name':
        setCustomersName(target.value);
        break;
      case 'email':
        setEmail(target.value);
        break;
      case 'note':
        setNote(target.value);
        break;
      default:
        break;
    }
  };

  async function post_affiliate(data) {
    const formData = new FormData();
    formData.append("client_name", data.client_name);
    formData.append("client_phone", data.client_phone);
    formData.append("client_email", data.client_email);
    formData.append("note", data.note);
    formData.append("global_company_name", globalCompanyName);
    formData.append("project_company", projectCompany);
    formData.append("settings_module", JSON.stringify(settingsState.settings));
    formData.append("type", type);

    try {
      const response = await fetch(TEST_URL, {
        method: 'POST',
        body: formData,
      });
      return await response.json()
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {
    const formData = {
      client_name: customersName,
      client_phone: phone,
      client_email: email,
      note: note,
    };
    const validation = isFormValid({ name: customersName, phone, email, checked }, errors);
    if (validation.result) {
      setIsLoading(true);
      await post_affiliate(formData)
        .then((response) => {
          if (response.status === 'fail') {
            dispatch(openNotification({ message: 'Your request has not been sent', status: 'error' }));
            setErrors(prevState => ({ ...prevState, email: 'Company email already exists' }));
          } else {
            dispatch(openNotification({ message: 'Your request has been sent', status: 'success' }));
          }
        });
      setCustomersName('')
      setEmail('')
      setNote('')
      setPhone('')
      setChecked(false)
      setIsLoading(false);
    } else {
      setErrors(() => ({ ...validation.errors }));
    }
  };


  useEffect(() => {
    getCountryByIP(setUserLocationData);
  }, []);

  return (
    <>
      <FormGroup sx={{
        flexGrow: 1,
        width: '100%',
        justifyContent: 'space-between',
        gap: '16px',
      }}>
        <Stack sx={{
          flexFlow: 'column nowrap',
          gap: '16px'
        }}>
          <TextField
            id='name'
            label='Name'
            variant='outlined'
            onChange={handleChange}
            name='name'
            value={customersName}
            error={!!errors.name}
            helperText={errors.name ?? ''}
            sx={{
              '& .MuiInputLabel-root': {
                color: '#8C95C1'
              },
              '& .Mui-focused.MuiInputLabel-root': {
                color: !errors.name ? '#1677FF' : '#d32f2f',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D5E3F3',
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: !errors.name && '#1677FF',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: !errors.name && '#1677FF',
                color: '#1677FF',
              },
            }}
          />
          <MuiTelInput
            label={'Phone'}
            name='phone'
            placeholder=' '
            value={phone}
            onChange={handleChangePhone}
            defaultCountry={userLocationData.country_code}
            forceCallingCode
            focusOnSelectCountry
            excludedCountries={['RU']}
            preferredCountries={preferredCountries}
            continents={['EU', 'OC', 'NA', 'SA']}
            error={!!errors.phone}
            helperText={errors.phone ?? ''}
            sx={{
              '& .MuiInputLabel-root': {
                color: '#8C95C1'
              },
              '& .Mui-focused.MuiInputLabel-root': {
                color: !errors.phone ? '#1677FF' : '#d32f2f',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D5E3F3',
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: !errors.phone && '#1677FF',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: !errors.phone && '#1677FF',
                color: '#1677FF',
              },
            }}
          />

          <TextField
            id='email-input'
            label='Email'
            variant='outlined'
            onChange={handleChange}
            name='email'
            type='email'
            value={email}
            error={!!errors.email}
            helperText={errors.email ?? ''}
            sx={{
              '& .MuiInputLabel-root': {
                color: '#8C95C1'
              },
              '& .Mui-focused.MuiInputLabel-root': {
                color: !errors.email ? '#1677FF' : '#d32f2f',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D5E3F3',
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: !errors.email && '#1677FF',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: !errors.email && '#1677FF',
                color: '#1677FF',
              },
            }}
          />

          <TextField
            id='note-input'
            label='Message'
            variant='outlined'
            onChange={handleChange}
            name='note'
            value={note}
            multiline
            sx={{
              '& .MuiInputLabel-root': {
                color: '#8C95C1'
              },
              '& .Mui-focused.MuiInputLabel-root': {
                color: '#1677FF',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D5E3F3',
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1677FF',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1677FF',
                color: '#1677FF',
              },
            }}
          />

          <FormControl error={!!errors.checked}>
            <FormControlLabel
              name='checked'
              checked={checked}
              onChange={handleChangeCheckbox}
              control={
                <Checkbox
                  sx={{
                    // color: !errors.checked && '#D5E3F3',
                    '& .MuiSvgIcon-root': {
                      fontSize: 29,
                      '& path': {
                        color: errors.checked ? '#D32F2F' : !checked ? '#D5E3F3' : '#1677FF',
                      }
                    },
                    '&:hover .MuiSvgIcon-root': {
                      '& path': {
                        color: !errors.checked && '#1677FF',
                      }
                    }
                  }} />
              }
              label={
                <Typography variant="body2" sx={{ color: !errors.checked ? '#292929' : '#D32F2F', fontWeight: 400 }}>
                  I agree to the processing of <Link href="https://rh-s.com/privacy" target="_blank" sx={{ color: '#1677FF', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>personal data</Link >
                </Typography>
              }
            />
            <FormHelperText>{errors.checked ?? ''}</FormHelperText>
          </FormControl>
        </Stack>
        <LoadingButton
          size={'large'}
          loading={isLoading}
          loadingPosition='end'
          endIcon={<SendIcon />}
          variant='contained'
          onClick={handleSubmit}
          sx={{
            height: '60px',
            borderRadius: '4px',
            backgroundColor: '#1677FF',
            '&:hover': {
              backgroundColor: 'rgb(15, 83, 178)',
              boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          Send
        </LoadingButton>
      </FormGroup>
    </>
  );
};
