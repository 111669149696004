import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CARDS_URL, FILTERS_URL, globalCompanyName } from "../../constants";

export const initialState = {
  employees: [],
  departments: [],
  positions: [],
  skills: [],
  languages: [],
  industries: [],
  isLoading: true,
  isFiltered: false,
  error: null,
  department_ids: [],
  position_ids: [],
  skill_ids: [],
  lang_ids: [],
  industries_ids: [],
  domain_type: "both",
  rate: "any",
  filterValues: {
    deps: [],
    pos: [],
    skills: [],
    langs: [],
    industries: []
  },
  filteredDepartments: [],
  filteredPositions: [],
  filteredSkills: [],
  filteredLangs: [],
  filteredIndustries: [],
  maxRate: 2,
  globalCompany: globalCompanyName,
  startPosition: '0',
  cardsLength: '-1',
  filteredEmployees: 0,
  totalEmployees: 0,
};


export const getCards = createAsyncThunk(
    'cards/fetchCards',
    async () => {
      const { data } = await axios({
            url: CARDS_URL,
            params: {
              global_company_name: initialState.globalCompany,
              start: initialState.startPosition,
              length: initialState.cardsLength,
            }
          }
      )
      return data.data;
    }
)

export const fetchFilterData = createAsyncThunk(
    "filter/fetchFilterData",
    async () => {
      const { data } = await axios({
        url: FILTERS_URL,
        params: {
          global_company_name: globalCompanyName,
        },
      });
      return data.data;
    }
);


export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    handleFilterTotal: (state, action) => {
      state.filteredEmployees = action.payload;
    },
    handleFilterValues: (state, action) => {
      const { id, values } = action.payload;
      state.filterValues[id] = [...values];
    },
    setDepartmentIds: (state, action) => {
      state.department_ids = action.payload;
    },
    setPositionIds: (state, action) => {
      state.position_ids = action.payload;
    },
    setSkillIds: (state, action) => {
      state.skill_ids = action.payload;
    },
    setLangIds: (state, action) => {
      state.lang_ids = action.payload;
    },
    setIndustriesIds: (state, action) => {
      state.industries_ids = action.payload;
    },
    setDomainType: (state, action) => {
      state.domain_type = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    setFilteredDepartments: (state, action) => {
      state.filteredDepartments = action.payload;
    },
    setFilteredPositions: (state, action) => {
      state.filteredPositions = action.payload;
    },
    setFilteredSkills: (state, action) => {
      state.filteredSkills = action.payload;
    },
    setFilteredLangs: (state, action) => {
      state.filteredLangs = action.payload;
    },
    setFilteredIndustries: (state, action) => {
      state.filteredIndustries = action.payload;
    },
    setMaxRate: (state, action) => {
      state.maxRate = action.payload;
    },
    reset: (state) => {
      state.department_ids = [];
      state.position_ids = [];
      state.skill_ids = [];
      state.lang_ids = [];
      state.industries_ids = [];
      state.filterValues.deps = []
      state.filterValues.pos = []
      state.filterValues.skills = []
      state.filterValues.langs = []
      state.filterValues.industries = []
      state.isFiltered = false
      state.rate = "any";
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchFilterData.pending, (state) => {
          state.error = null;
          state.isLoading = true;
          state.isFiltered = false;
        })
        .addCase(fetchFilterData.fulfilled, (state, action) => {
          state.error = null;
          state.departments = action.payload.departments;
          state.positions = action.payload.positions;
          state.skills = action.payload.skills;
          state.languages = action.payload.languages;
          state.filteredSkills = action.payload.skills;
          state.filteredLangs = action.payload.languages;
          state.industries = action.payload.tags;
        })
        .addCase(fetchFilterData.rejected, (state, action) => {
          state.error = action.error;
          state.isLoading = false;
          state.isFiltered = false;
        })
        .addCase(getCards.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getCards.fulfilled, (state, action) => {
          state.employees = action.payload.employees;
          state.totalEmployees = action.payload.total;
          state.isLoading = false;
        })
  },
});
export const {
  setDepartmentIds,
  setIndustriesIds,
  setDomainType,
  setLangIds,
  setPositionIds,
  setSkillIds,
  setIsFiltered,
  setRate,
  setFilteredDepartments,
  setFilteredPositions,
  setFilteredSkills,
  setFilteredLangs,
  setFilteredIndustries,
  setMaxRate,
  reset,
  handleFilterValues,
  handleFilterTotal,
} = filterSlice.actions;

export default filterSlice.reducer;
