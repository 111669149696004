import {
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey } from "@mui/material/colors";

export default function Experience({ item }) {
  const [open, setOpen] = useState(false);
  const {
    company_name,
    position,
    experiences_text,
    start_date_unix,
    end_date_unix,
    experience_tags
  } = item;
  const startDate = new Date(start_date_unix * 1000).toLocaleDateString("default", {
    year: "numeric",
    month: "2-digit",
  });
  const endDate = end_date_unix
      ? new Date(end_date_unix * 1000).toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
      })
      : "Present";
  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)} selected={open}>
          <ListItemIcon>
            <BusinessIcon/>
          </ListItemIcon>
          <ListItemText
              primaryTypographyProps={{
                component: "div",
              }}
              primary={
                <Typography
                    sx={{
                      fontVariationSettings: `'wdth' 80, 'wght' 600`,
                    }}>
                  {company_name}
                </Typography>
              }
              secondaryTypographyProps={{
                component: "div",
              }}
              secondary={
                <Stack
                    sx={{
                      flexFlow: "row nowrap",
                      gap: "1rem",
                    }}>
                  <Stack
                      sx={{
                        flexFlow: "row wrap",
                        alignItems: "center",
                        gap: ".3rem 1rem",
                      }}>
                    {position}
                    <Chip
                        icon={<AccessTimeIcon/>}
                        label={`${startDate} - ${endDate}`}
                        size={"small"}
                        variant="filled"
                        sx={{
                          backgroundColor: grey[50],
                        }}
                    />
                  </Stack>
                </Stack>
              }
          />
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemText
                primaryTypographyProps={{
                  component: 'div',
                }}
                secondaryTypographyProps={{
                  component: 'div',
                }}
                primary={
                  <Typography
                      variant={'body2'}
                      component={'div'}
                      display={'flex'}
                      flexDirection={'column'}
                      gap={'1dvh'}
                      lineHeight={'1.5'}
                      sx={{
                        "& ul": {
                          padding: '0 1rem'
                        },
                        "& li": {
                          listStyleType: "disc",
                          // listStylePosition: "inside"
                        }

                      }}
                      dangerouslySetInnerHTML={{__html: experiences_text}}
                  />
                }
                secondary={
                  <Stack sx={{
                    width: '100%',
                    display: 'inline-flex',
                    flexFlow: 'row wrap',
                    gap: '.5dvh',
                    padding: '1dvh',
                    border: '1px solid rgba(25, 118, 210, 0.08)',
                    borderRadius: ' 1rem',
                  }}>
                    <Typography fontWeight={'bold'} color={grey[400]}> Position tags:</Typography>
                    <Stack sx={{
                      flexFlow: 'row wrap',
                      gap: '1dvh',
                    }}>
                      {experience_tags.map((tag) => (
                          <Chip
                              label={tag}
                              key={tag}
                              size={'small'}
                              color={'primary'}
                              variant={'outlined'}
                              sx={{
                                fontVariationSettings: `'wdth' 88`,
                              }}
                          />
                      ))}
                    </Stack>

                  </Stack>
                }
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  gap: '1dvh'
                }}
            />
          </List>
        </Collapse>
      </>
  );
}
