import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { validateOrderData } from "../../../../lib/validateData";
import { getSettings } from "../../../../redux/slices/settingsSlice";
import { openNotification } from "../../../../redux/slices/notificationSlice";
import { saveAffiliateCode } from "../../../../redux/localStorage";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import CircularProgress from '@mui/material/CircularProgress';

const AffiliateInput = ({ setFormOpen, setDemoMode, setShowInfo, loading, setLoading }) => {
  const dispatch = useDispatch()

  const [customersAffiliateCode, setCustomersAffiliateCode] = useState('')
  const [errors, setErrors] = useState('');

  async function getAffiliateSettings() {
    if (customersAffiliateCode.length !== 12) {
      setErrors('Please enter a valid affiliate code')
      return
    }
    setLoading(true)
    await dispatch(getSettings(customersAffiliateCode))
      .then((response) => {
        if (response.error) {
          setLoading(false)
          setErrors(response.error.message)
          dispatch(openNotification({ message: response.error.message, status: 'error' }));
        } else {
          setFormOpen(false)
          setDemoMode(true)
          setShowInfo(true)
          setLoading(false)
          saveAffiliateCode(customersAffiliateCode)
          dispatch(openNotification({ message: response.payload.message, status: 'success' }));
        }
      })
  }

  const handleChange = ({ target }) => {
    if (!!errors) {
      setErrors('')
    }
    const validationResult = validateOrderData(target.name, target.value);
    setErrors(validationResult)
    setCustomersAffiliateCode(target.value);
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        width: '100%',
        alignSelf: 'center',
        '& .MuiInputLabel-root': {
          color: '#8C95C1'
        },
        '& .Mui-focused.MuiInputLabel-root': {
          color: !errors ? '#1677FF' : '#d32f2f',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D5E3F3',
        },
      }}
    >
      <InputLabel
        htmlFor="outlined-adornment-affiliate-code"
        error={!!errors}
      >
        Affiliate code
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-affiliate-code"
        type='text'
        name='affiliateCode'
        value={customersAffiliateCode}
        error={!!errors}
        onChange={handleChange}
        label="Affiliate code"
        sx={{
          paddingRight: '1px',

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: !errors && '#1677FF',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: !errors && '#1677FF',
            color: '#1677FF',
          },
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            getAffiliateSettings();
          }
        }}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{
              m: 0,
              height: '56px',
              maxHeight: '56px',
            }}
          >
            <LoadingButton
              aria-label="send affiliate code"
              onClick={getAffiliateSettings}
              disabled={customersAffiliateCode.length === 0}
              loading={loading}
              sx={{
                width: '100%',
                height: '100%',
                color: !errors ? '#1677FF' : '#d32f2f',
                '&:disabled': {
                  color: !errors ? '#1677FF' : '#d32f2f',
                  opacity: 0.2
                }
              }}
              edge="end"
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
            </LoadingButton>
          </InputAdornment>
        }
      />
      {!!errors && (
        <FormHelperText error id="outlined-adornment-affiliate-code">
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AffiliateInput;
