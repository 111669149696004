import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SpaLink({ children, to, ...props }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };
  return (
      <Box onClick={handleClick} {...props}>
        {children}
      </Box>
  );
}
