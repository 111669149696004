import { Box } from '@mui/material';
import { gFetch } from "../../lib/fetch";


export default function TinyRender({ htmlString, sx = {}, ...props }) {
  // Replace relative paths in image src with absolute paths
  const updateHtmlString = htmlString.replace(/(\.\.\/)+uploads/g, `${gFetch.newStrapi.url}/uploads`);
  const withoutMarker = updateHtmlString.replace(/::marker/g, '');
  const parser = new DOMParser();
  const doc = parser.parseFromString(withoutMarker, 'text/html');

  const imgTags = doc.querySelectorAll('img');
  imgTags.forEach((imgTag) => {
    imgTag.setAttribute('loading', 'lazy');
    imgTag.style.width = '100%';
    imgTag.style.height = 'auto';
    imgTag.setAttribute('width', '800');
    imgTag.setAttribute('height', '800');
  });

  const updatedHtmlString = doc.body.innerHTML;

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: updatedHtmlString }}
      {...props}
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        overflow: 'hidden',
        width: '100%',
        gap: '.5rem',

        ...sx,
        '& h2': {
          fontSize: '1rem',
        },
        '& p': {
          width: '100%',

          lineBreak: 'normal',
        },
        '& ol': {
          listStylePosition: 'inside',
          margin: 0,
          padding: '0rem 1rem',
          width: '100%',
        },
      }}
    ></Box>
  );
}
