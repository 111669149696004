import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import {
  handleFilterValues,
} from "../../../../redux/slices/filterSlice";
import { editFilter } from "../../../../redux/slices/settingsSlice";
import { storeIDs } from "../../../../lib/mapData";

export function SettingsFilter({ id, values, disabled = false, filterName }) {
  const dispatch = useDispatch();
  const { departments, positions, languages } = useSelector((store) => store.filterSlice);
  const filter = useSelector((store) => store.settingsSlice.settings.filter);

  const filterOptions = useMemo(() => {
    const newOptions = [];

    if (id === "pos" && filter.department.length > 0) {
      const newValues = values.filter((v) =>
        filter.department.includes(Number(v.department_id))
      );
      for (const v of newValues) {
        newOptions.push({
          id: Number(v.id),
          name: v.name,
          label: v.name,
          value: Number(v.id),
        });
      }
    } else {
      for (const v of values) {
        newOptions.push({
          id: Number(v.id),
          name: v.name,
          label: v.name,
          value: Number(v.id),
        });
      }
    }
    const filterValues = filter[filterName] || [];
    for (const value of filterValues) {
      if (!newOptions.some(option => option.value === value)) {
        const item = (() => {
          switch (id) {
            case 'deps':
              return departments.find(i => i.id === value);
            case 'pos':
              return positions.find(i => i.id === value);
            case 'langs':
              return languages.find(i => Number(i.id) === value);
            default:
              return null;
          }
        })();

        if (item) {
          newOptions.push({
            id: Number(item.id),
            name: item.name,
            label: item.name,
            value: Number(item.id),
          });
        }
      }
    }
    return newOptions;
  }, [departments, filter, filterName, id, languages, positions, values]);

  const handleChange = (event, values) => {
    const action = storeIDs.find((a) => a.id === id).filterAction;
    const newValuesArr = values.map((item) => item.value);
    dispatch(action(newValuesArr));

    dispatch(handleFilterValues({ id, values }))
    dispatch(editFilter({ name: filterName, value: values.map(v => Number(v.id)) }));
  }

  return (
    <Autocomplete
      multiple fullWidth limitTags={3}
      options={filterOptions}
      value={filter[filterName].map(v => {
        let item;

        switch (id) {
          case 'deps':
            item = departments.find(i => i.id === v);
            break;
          case 'pos':
            item = positions.find(i => i.id === v);
            break;
          case 'langs':
            item = languages.find(i => Number(i.id) === v);
            break;

          default:
            break;
        }

        if (item) {
          return { id: Number(item.id), name: item.name, value: Number(item.id), label: item.name };
        }

        return null;
      }).filter(Boolean)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.id}
            label={option.label}
            variant="outlined"
            sx={{
              // backgroundColor: 'rgba(96,165,250,0.2)',
              border: `1px solid #C9D0E7`,
              color: '#000000',
              borderRadius: '100px',
              '& .MuiChip-deleteIcon': {
                color: '#8C95C1',
                // fontSize: '1rem',
                // '&:hover': {
                //   color: 'black',
                // }
              },
            }}
          // deleteIcon={<ClearRoundedIcon />}
          />
        ));
      }}
      onChange={handleChange}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label={storeIDs.find((sId) => sId.id === id).placeholder}
          // sx={{
          //   '& .Mui-focused.MuiInputLabel-root': {
          //     color: '#1677FF',
          //   },
          // }}
        />
      )}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{
        '& .MuiInputLabel-root': {
          color: '#8C95C1'
        },
        '& .Mui-focused.MuiInputLabel-root': {
          color: '#1677FF',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D5E3F3',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#1677FF',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#1677FF',
          color: '#1677FF',
        },
        '& .MuiAutocomplete-popupIndicator': {
          color: '#8C95C1'
        },
      }}
    />
  );
}
