/*----------- FORM CONFIG ---------*/
const POST_URL = "https://crm-s.com/api/v1/leads-public";
const TEST_URL = "https://crm.rhelpers.com/api/v1/leads-public";
const CARDS_URL = "https://crm-s.com/api/v1/employees-short-list";
const FILTERS_URL = "https://crm-s.com/api/v1/employees-filters-data";

const globalCompanyName = "Remote Helpers"; // "Worksuite Demo Company"
const projectCompany = "rh-s.com";
const promocode = "T42MXKAMM2ZV";
const type = "affiliate";

const preferredCountries = [
  'CH',
  'DE',
  'US',
  'SE',
  'GB',
  'NL',
  'FR',
  'FI',
  'PT',
  'CZ',
  'CA',
  'IE',
  'IL',
  'IT',
  'DK',
  'IS',
  'LV',
  'MT',
  'NO',
  'ES',
  'SK',
  'BE',
  'LU',
  'AT',
  'NZ',
  'PL',
  'SG',
];


export {
  POST_URL,
  TEST_URL,
  CARDS_URL,
  FILTERS_URL,
  globalCompanyName,
  projectCompany,
  promocode,
  type,
  preferredCountries,
};
