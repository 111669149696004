import { Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotification } from "../../redux/slices/notificationSlice";
import { Alert } from "@mui/material";

export const Notification = () => {
  const { isOpened, message, status } = useSelector((store) => store.notificationSlice);
  const dispatch = useDispatch();


  return (
      <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpened}
          autoHideDuration={4000}
          onClose={() => dispatch(closeNotification())}
          sx={{ top: '4.35rem', justifyContent: 'flex-end', width: 'max-content', left: 'auto' }}
      >
        <Alert
            severity={status}
            variant="filled"
            elevation={10}
            direction='down'
            sx={{
              marginRight: '.4rem'
            }}
        >
          {message}
        </Alert>
      </Snackbar>
  );
};
