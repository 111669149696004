import React, { memo, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Button, Popover } from "@mui/material";
import { useDispatch } from "react-redux";
import { getContrastColor } from "../../../../lib/helpers";

const SketchExample = memo(({ color, name, label, editFunc, disabled }) => {
  const dispatch = useDispatch()

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleCompleteChange = ({ rgb }) => {
    dispatch(editFunc({ name: name, value: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` }))
  };

  return (
    <fieldset style={{
      display: 'flex',
      alignItems: 'end',
      border: displayColorPicker ? 'solid 1px BlueViolet' : 'solid 1px #D5E3F3',
      padding: '0 12px 16px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      height: '65px',
      flex: 1,
    }}>
      <legend style={{
        fontSize: '12px',
        padding: '0 5px',
        maxWidth: 'calc(144% - 32px)',
        overflow: 'hidden',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
        marginLeft: '-5px',
        color: displayColorPicker ? 'BlueViolet' : '#8C95C1',
      }}>{label}</legend>
      <Button
        disabled={disabled}
        aria-describedby={displayColorPicker ? 'color-picker' : undefined}
        variant="contained"
        style={{
          color: getContrastColor(color),
          background: color,
          width: '100%',
          height: '24px',
        }}
        sx={{
          fontSize: '1rem'
        }}
        onClick={handleClick}
      >
      </Button>

      <Popover
        id="color-picker"
        open={displayColorPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformorigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        }} onClick={handleClose} />
        <SketchPicker
          color={color}
          onChangeComplete={handleCompleteChange}
        />
      </Popover>
    </fieldset>
  );
});

export default SketchExample;
