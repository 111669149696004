import { useDispatch } from 'react-redux';
import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { green, orange } from "@mui/material/colors";
import { removeStaffItem } from "../../redux/slices/cartSlice";
import { checkReselect, generateEmployeeStatus } from "../../lib/order-helpers";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export const BookingList = ({ orderList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRemoveBtn = employee_id => dispatch(removeStaffItem(employee_id))
  const handleEyeClick = slug => navigate(`/${slug}`);
  const handleClick = () => navigate('/');

  function AddBtn({ text, handleClick }) {
    return (
        <Button
            variant="contained"
            size={'large'}
            onClick={handleClick} endIcon={<PersonAddIcon/>}
            sx={{
              marginTop: '1rem',
              alignSelf: 'flex-end',
              borderRadius: '.5rem',
            }}
        >
          {text}
        </Button>
    )
  }

  return (
      <Box sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '.5rem',
        // backgroundColor: 'transparent',
      }}>
        {orderList.map(({ slug, image_url, short_name, position_name, sum_rate_rh, rate, employee_id }) => {
          const employeeStatus = generateEmployeeStatus(sum_rate_rh);
          const isReselectNeeded = checkReselect(rate, sum_rate_rh);
          const accentColor = isReselectNeeded ? orange[500] : green[500];
          return (
              <Paper
                  key={slug + '_MenuItem'}
                  display="flex"
                  sx={{
                    boxShadow: '0 .1rem .3rem rgba(0, 0, 0, .1)',
                    flexFlow: 'column nowrap',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    borderLeft: `.25rem solid ${accentColor}`,
                    padding: '.5rem',
                  }}
              >
                <Stack sx={{
                  flexFlow: 'row nowrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                  <Stack sx={{
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',

                  }}
                  >
                    <Box sx={{
                      minWidth: '4rem',
                      borderRadius: '50%',
                      aspectRatio: '1',
                      flexGrow: 1,
                      background: `url(${image_url})`,
                      backgroundSize: 'cover',
                      border: '1px solid #ccc',
                    }}/>
                    <Stack sx={{ flexFlow: "column nowrap", gap: "2px", padding: '.3rem 0' }}>
                      <Typography variant={'body1'} fontWeight={'bold'}>{short_name}</Typography>
                      <Typography variant={'body2'}>{position_name}</Typography>
                      <Typography variant={'caption'} display={'flex'} gap={'.5rem'}>
                        Status:
                        <Typography component={'span'} variant={'caption'} color={accentColor}>
                          {employeeStatus}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction='row' sx={{}}>
                    <IconButton onClick={() => handleEyeClick(slug)}>
                      <VisibilityIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleRemoveBtn(employee_id)}><PersonRemoveIcon/></IconButton>
                  </Stack>
                </Stack>
              </Paper>
          )
        })}
        <AddBtn text='Add more employees' handleClick={handleClick}/>
      </Box>
  );
};
