import {
  Box,
  Card,
  Stack,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Button,
  Paper,
  MenuList,
  ListItemText,
  MenuItem,
} from '@mui/material';
import DynamicPlayer from '../../Player';
import { useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { grey } from '@mui/material/colors';
import { Pricing } from "./Pricing";

export default function ClientCV({ emp, defaultVideoSrc }) {
  const {
    slug,
    employee_id,
    short_name,
    position_name,
    image_url,
    secondaryPosition,
    full_price,
    part_price,
    min_price,
    full_symbol,
    sum_rate_rh,
    url_video_to_youtube
  } = emp;
  const orderList = useSelector((state) => state.cartSlice.selectedStaff);
  const {
    primaryColor,
  } = useSelector(state => state.settingsSlice.settings.style.main);
  const {
    cvAvatarBackground
  } = useSelector(state => state.settingsSlice.settings.style.card);
  const activeEmployee = orderList.find((employee) => employee.slug === slug);
  const [activePosition, setActivePosition] = useState(activeEmployee?.position_name ?? position_name);
  const [open, setOpen] = useState(false);
  const cardRef = useRef();

  const bakedPricing = useCallback(() => {
    const rate = Number(sum_rate_rh);
    const maxHours = 160;
    const ind = secondaryPosition.findIndex((item) => item?.name === activePosition);
    let minPrice = min_price;
    let partPrice = part_price;
    let fullPrice = full_price;
    if (activePosition !== position_name) {
      minPrice = secondaryPosition[ind]?.minimum_price ?? '----';
      partPrice = secondaryPosition[ind]?.part_price ?? '----';
      fullPrice = secondaryPosition[ind]?.full_price ?? '----';
    }
    const pricingArr = [
      {
        title: 'Minimal',
        value: Number.isNaN(+minPrice) ? minPrice : +minPrice,
        hours: maxHours * 0.25,
        disabled: !rate,
      },
      {
        title: 'Part-time',
        value: Number.isNaN(+partPrice) ? partPrice : +partPrice,
        hours: maxHours * 0.5,
        disabled: rate < 0.5,
      },
      {
        title: 'Full-time',
        value: Number.isNaN(+fullPrice) ? fullPrice : +fullPrice,
        hours: maxHours,
        disabled: rate < 1,
      },
    ];
    return pricingArr.map((item) => ({
      ...item,
      currency: full_symbol,
    }));
  }, [activePosition, secondaryPosition, position_name, full_symbol, sum_rate_rh, full_price, part_price, min_price]);

  const handleMenuClick = (e) => {
    setActivePosition(e.target.innerText);
    setOpen(false);
  };

  return (
      <>
        <Card
            ref={cardRef}
            sx={{
              width: '100%',
              minWidth: 'inherit',
              overflow: 'hidden',
              position: 'relative',
            }}
        >
          <Stack
              sx={{
                width: '100%',
                aspectRatio: 1,
                display: 'flex',
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
                background: cvAvatarBackground,
                userSelect: 'none',
              }}
          >
            <CardMedia
                component='img'
                image={image_url}
                alt={`${short_name}-${position_name}`}
                sx={{
                  objectFit: 'cover',
                  userSelect: 'none',
                  filter: 'drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    scale: '1.1',
                  },
                }}
            />
          </Stack>
          <CardContent>
            <Typography
                variant='h5'
                color='text.secondary'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontFamily: 'unset',
                  fontWeight: '750',
                  fontVariationSettings: `'wdth' 95`,
                  flexFlow: 'row wrap',
                }}
            >
              {short_name}
              <Chip icon={<FingerprintIcon color={'primary'}/>} label={employee_id} variant='outlined'/>
            </Typography>
            <Stack sx={{ flexFlow: 'row nowrap', padding: '.5rem 0' }} justifyContent='space-between'
                   alignItems='center'>
              <Typography
                  variant='button'
                  color='text.secondary'
                  sx={{
                    fontFamily: 'unset',
                    fontWeight: '700',
                    fontVariationSettings: `'wdth' 80`,
                    fontSize: '1.1rem'
                  }}
              >
                {activePosition}
              </Typography>
              {secondaryPosition?.length > 0 && (
                  <Chip
                      label={activePosition === position_name ? 'Primary' : 'Alternative'}
                      size='small'
                      color={activePosition === position_name ? 'primary' : 'secondary'}
                  />
              )}
            </Stack>
            {secondaryPosition?.length > 0 && (
                <>
                  <Button fullWidth variant='contained' sx={{ margin: '.5rem 0' }} size='small'
                          onClick={() => setOpen(true)}>
                    Switch to alternative position
                  </Button>
                  {open && (
                      <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: '50',
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(0,0,0,.5)',
                          }}
                      ></Box>
                  )}
                  <Paper
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        zIndex: 80,
                        width: '100%',
                        opacity: open ? 1 : 0,
                        translate: open ? '0 0' : '0 100%',
                        transition: 'all .2s cubic-bezier(.23,.73,.83,.67)',
                      }}
                  >
                    <Stack justifyContent='flex-end' alignItems='flex-end' sx={{ padding: '2px' }}>
                      <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon/>
                      </IconButton>
                    </Stack>
                    <MenuList>
                      <MenuItem
                          sx={{ borderBottom: `1px solid ${grey[200]}`, color: primaryColor }}
                          selected={position_name === activePosition}
                          onClick={handleMenuClick}
                      >
                        <ListItemText>{position_name}</ListItemText>
                      </MenuItem>
                      {secondaryPosition.map((item, ind) => {
                        return (
                            <MenuItem
                                key={item.id}
                                sx={{ borderBottom: ind !== secondaryPosition.length - 1 ? `1px solid ${grey[200]}` : '' }}
                                selected={item?.name === activePosition}
                                onClick={handleMenuClick}
                            >
                              <ListItemText>{item?.name}</ListItemText>
                            </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Paper>
                </>
            )}
          </CardContent>
        </Card>
        <DynamicPlayer url={url_video_to_youtube} defaultUrl={defaultVideoSrc} isMobile/>
        <Pricing pricing={bakedPricing()} emp={emp} activePosition={activePosition}/>
      </>
  );
}
