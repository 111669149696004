import  mainLogo from "../../assets/logo_svg_full.svg";

function Logo({ size }) {

  return <img
      style={{
        minWidth: "280px",
        width: size === 's' ? '30%' : '90%',
      }}
      src={mainLogo}
      alt={'logo-full'}
  />
}

export { Logo }
