import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Chip, Grow,
  List,
  Paper,
  Stack,
  Typography, Zoom
} from "@mui/material";

/*Components*/
import WorkExperience from "./components/Experience";
import Education from "./components/Education";
import DynamicPlayer from '../Player';
import SkeletonCV from "../Skeleton/components/SkeletonCV";
import Responsibilities from "./components/Responsibilities";
import { getContrastColor, getVideoSrc } from "../../lib/helpers";
import { useSelector } from "react-redux";
import { get_strapi, gFetch, qlQueries } from "../../lib/fetch";
import ClientCV from "./components/ClientCV";
import { Case } from "./components/Case";

function CV() {
  const {
    secondaryColor
  } = useSelector(state => state.settingsSlice.settings.style.main);

  const { employees } = useSelector(state => state.filterSlice);

  const location = useLocation();
  const navigate = useNavigate();
  const boxRef = useRef(null);

  /*Statement*/
  const [isLoading, setLoading] = useState(true);
  const [cvData, setCvData] = useState({});
  const [cvVideos, setCvVideos] = useState()
  const [cases, setCases] = useState([])

  async function getData(emp) {
    const prepDepartment = emp.department.trim().replace(/[A-Z]/g, match => match.toLowerCase()).replace(/\s/g, '-');
    const prepPosition = emp.position_name.trim().replace(/[A-Z]/g, match => match.toLowerCase()).replace(/\s/g, '-');
    const promisesArray = [
      get_strapi(gFetch.newStrapi.path('/graphql'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: gFetch.newStrapi.token,
        },
        body: JSON.stringify(qlQueries.getCases(emp.employee_id)),
      }),
      get_strapi(gFetch.newStrapi.path('/graphql'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: gFetch.newStrapi.token,
        },
        body: JSON.stringify(qlQueries.getPositionVideo(prepPosition)),
      }),
      get_strapi(gFetch.newStrapi.path('/graphql'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: gFetch.newStrapi.token,
        },
        body: JSON.stringify(qlQueries.getDepartmentVideo(prepDepartment)),
      }),
    ];
    return await Promise.allSettled(promisesArray);
  }

  /*Effects*/
  useEffect(() => {
    if (!employees.length) {
      return;
    }
    const foundEmployee = employees.find(emp => emp.slug === location.pathname.slice(1));

    if (foundEmployee) {
      const { model_name } = foundEmployee;
      axios({
        url: `https://crm-s.com/api/v1/employees${location.pathname}`,
        params: {
          global_company_name: "Remote Helpers",
          model_name: model_name,
        },
      })
          .then((response) => {
            setCvData(response.data.data.employee);
            getData(response.data.data.employee).then(res => {
              const [{ value: strapiCases }, { value: posVideo }, { value: depVideo },] = res;
              setCvVideos(getVideoSrc(posVideo, depVideo))
              setCases(strapiCases?.cases?.data)
            });
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            navigate("/404");
          });
    } else {
      setLoading(false);
      navigate("/404");
    }

  }, [location.pathname, navigate, employees]);

  const {
    skills,
    work_experiences,
    educations,
    url_video_to_youtube,
    languages,
    seo_title
  } = cvData;

  return (
      isLoading
          ? <SkeletonCV/>
          :
          <Box sx={{
            backgroundColor: "transparent",
            paddingX: '1rem',
            display: "flex",
            minHeight: '10rem',
            width: '100%',
            flexFlow: "row wrap",
            paddingTop: !seo_title ? '3rem' : 0,
            paddingBottom: '6rem',
            maxWidth: '90rem',
          }}>
            {seo_title && (
                <Typography
                    component='h1'
                    variant='h1'
                    sx={{
                      fontSize: 'max(1.25rem, 2vw)',
                      fontVariationSettings: `'wght' 700`,
                      padding: '1.5rem 0',
                      alignSelf: 'start'
                    }}
                >
                  {seo_title}
                </Typography>
            )}

            <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  columnGap: '3%',
                  rowGap: '2dvh',
                }}
            >

              {/*////////////////////Left Side//////////////////////////*/}
              <Grow in timeout={-5}>
                <Box sx={{
                  display: "flex",
                  minHeight: '10rem',
                  minWidth: '280px',
                  width: '16%',
                  flexGrow: 1,
                  flexDirection: 'column',
                  gap: "2.4dvw",
                }}>

                  {/*////////////////////Employee Card, Player, Pricing//////////////////////////*/}

                  <ClientCV emp={cvData} defaultVideoSrc={cvVideos}/>
                  <Responsibilities workExperiences={work_experiences} isMobile/>

                  {/*////////////////////Skills//////////////////////////*/}

                  <Paper
                      sx={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        fontFamily: 'unset',
                        overflow: 'hidden',
                      }}>
                    <Typography
                        variant={"button"}
                        sx={{
                          backgroundColor: secondaryColor || '#9c27b0',
                          padding: ".7rem 1rem",
                          color: getContrastColor(secondaryColor || '#9c27b0'),
                        }}>
                      Skills & Tools
                    </Typography>
                    <Stack
                        sx={{
                          flexFlow: "row wrap",
                          gap: ".5rem",
                          padding: "1rem",
                        }}>
                      {skills ? (
                          skills.map((item, index) => (
                              <Zoom in key={index}>
                                <Chip
                                    label={item.name}
                                    variant='outlined'
                                    size={'small'}
                                    sx={{
                                      fontVariationSettings: `'wdth' 88, 'wght' 500`,
                                    }}
                                />
                              </Zoom>
                          ))
                      ) : (
                          <Chip
                              label='N/A'
                              variant='outlined'
                              size={'small'}
                              sx={{
                                fontVariationSettings: `'wdth' 88, 'wght' 500`,
                              }}
                          />
                      )}
                    </Stack>
                  </Paper>

                  {/*////////////////////Languages//////////////////////////*/}

                  {languages && languages.length > 0 && (
                      <Paper
                          sx={{
                            width: '100%',
                            minWidth: '280px',
                            display: 'flex',
                            flexFlow: 'column nowrap',
                            overflow: 'hidden',
                          }}>
                        <Typography
                            variant={"button"}
                            sx={{
                              backgroundColor: secondaryColor || '#e91e63',
                              padding: ".7rem 1rem",
                              color: getContrastColor(secondaryColor || '#e91e63'),
                            }}>
                          Languages
                        </Typography>
                        <Stack sx={{
                          padding: '1rem',
                          gap: '1rem',
                          flexFlow: 'row wrap'
                        }}>
                          {languages.map((item, index) => (
                              <Zoom in key={index}>
                                <Chip
                                    label={`${item.name} - ${item.level_short_name} (${item.level_ext_name})`}
                                    variant="outlined"
                                    sx={{
                                      fontVariationSettings: `'wdth' 88, 'wght' 500`,
                                      flexGrow: 1,
                                      minWidth: '10rem'
                                    }}
                                />
                              </Zoom>
                          ))}
                        </Stack>
                      </Paper>
                  )}
                </Box>
              </Grow>

              {/*////////////////////Right Side//////////////////////////*/}

              <Grow in>
                <Box ref={boxRef} sx={{
                  display: "flex",
                  // minHeight: '10rem',
                  minWidth: '15rem',
                  width: '53%',
                  flexGrow: '1',
                  flexDirection: 'column',
                  gap: "2.4dvw",
                }}>

                  {/*////////////////////Video player//////////////////////////*/}

                  <DynamicPlayer url={url_video_to_youtube} defaultUrl={cvVideos}/>

                  {/*////////////////////Key Responsibilities//////////////////////////*/}

                  <Responsibilities workExperiences={work_experiences}/>

                  {/*////////////////////Experience//////////////////////////*/}

                  {work_experiences && work_experiences.length > 0 && (
                      <Paper
                          sx={{
                            width: '100%',
                            minWidth: '280px',
                            display: 'flex',
                            flexFlow: 'column nowrap',
                            overflow: 'hidden',
                          }}>
                        <Typography
                            variant={"button"}
                            sx={{
                              backgroundColor: secondaryColor || '#FFC107',
                              padding: ".7rem 1rem",
                              color: getContrastColor(secondaryColor || '#FFC107'),
                            }}
                        >
                          Experience
                        </Typography>
                        <List sx={{ width: "100%" }} aria-labelledby="work-experience">
                          {work_experiences
                              .sort((a, b) => {
                                if (!a.end_date_unix && !b.end_date_unix) {
                                  return b.start_date_unix - a.start_date_unix;
                                }
                                if (!a.end_date_unix) {
                                  return -1;
                                }
                                if (!b.end_date_unix) {
                                  return 1;
                                }
                                return b.end_date_unix - a.end_date_unix;
                              })
                              .map((item, index) => (
                                  <WorkExperience key={index} item={item}/>
                              ))}
                        </List>
                      </Paper>
                  )}

                  {/*////////////////////Education//////////////////////////*/}

                  {educations && educations.length > 0 && (
                      <Paper
                          sx={{
                            width: '100%',
                            minWidth: '280px',
                            display: 'flex',
                            flexFlow: 'column nowrap',
                            overflow: 'hidden',
                          }}>
                        <Typography
                            variant={"button"}
                            sx={{
                              backgroundColor: secondaryColor || '#e91e63',
                              padding: ".7rem 1rem",
                              color: getContrastColor(secondaryColor || '#e91e63'),
                            }}>
                          Education
                        </Typography>
                        <List sx={{ width: "100%" }} aria-labelledby="education">
                          {educations
                              .sort((a, b) => {
                                if (!a.end_date_unix && !b.end_date_unix) {
                                  return b.start_date_unix - a.start_date_unix;
                                }
                                if (!a.end_date_unix) {
                                  return -1;
                                }
                                if (!b.end_date_unix) {
                                  return 1;
                                }
                                return b.end_date_unix - a.end_date_unix;
                              })
                              .map((item, index) => (
                                  <Education item={item} key={index}/>
                              ))}
                        </List>
                      </Paper>
                  )}

                  {/*////////////////////Portfolio//////////////////////////*/}

                  {cases && cases.length > 0 && (
                      <Paper
                          sx={{
                            width: '100%',
                            minWidth: '280px',
                            display: 'flex',
                            flexFlow: 'column nowrap',
                            overflow: 'hidden',
                          }}
                      >
                        <Typography variant={'button'}
                                    sx={{
                                      backgroundColor: secondaryColor || '#e91e63',
                                      padding: ".7rem 1rem",
                                      color: getContrastColor(secondaryColor || '#e91e63'),
                                    }}>
                          Portfolio
                        </Typography>
                        <Box
                            component='section'
                            width='100%'
                            sx={{
                              display: 'flex',
                              flexFlow: 'row wrap',
                              gap: '2dvh',
                              padding: '1rem',
                            }}
                        >
                          {cases.map((item, index) => (
                              <Case key={item.id} ind={index} cases={cases}/>
                          ))}
                        </Box>
                      </Paper>
                  )}
                </Box>
              </Grow>
            </Box>
          </Box>
  );
}

export { CV };
