import React, { useState } from 'react';
import { Backdrop, Box, Button, Divider, Fade, Modal, Typography } from "@mui/material";
import AffiliateInput from "../Settings/components/AffiliateInput/AffiliateInput";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const GreetingModal = ({view, demoMode, setDemoMode, setFormOpen, setShowInfo}) => {
  const [loading, setLoading] = useState(false);

  const handleDemoMode = () => {
    setDemoMode(true)
    setShowInfo(true)
    sessionStorage.setItem('demoMode', true)
  };

  return (
      <Modal
          key={view}
          open={!demoMode}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          disableScrollLock={view}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
      >
        <Fade in={!demoMode}>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 497,
                '@media (max-width: 600px)': {
                  width: '90%',
                },
                backgroundColor: 'white',
                '&:focus': {
                  outline: 'none'
                },
                boxShadow: 24,
                p: '22px 40px 35px',
                borderRadius: '8px',
              }}
          >
            {/* <IconButton variant="outlined" size={'small'} sx={{ p: '3px', mb: '10px', backgroundColor: 'rgba(96,165,250,0.4)', borderRadius: '50%', border: '5px solid rgba(96,165,250,0.1)', }}> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', backgroundColor: 'rgba(96,165,250,0.05)', borderRadius: '50%', mb: '10px', p: '5px' }}>
              <InfoOutlinedIcon color={'primary'} sx={{ width: '30px', height: '30px', p: '3px', backgroundColor: 'rgba(96,165,250,0.1)', borderRadius: '50%', }} />
            </Box>
            {/* </IconButton> */}
            <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                gutterBottom
                sx={{
                  // textWrap: 'balance',
                  marginBottom: '22px',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
            >
              {`To unblock all settings and start creating a module's unique style for your site, click the button below and enter your affiliate code`}
            </Typography>
            <AffiliateInput
                setFormOpen={setFormOpen}
                setDemoMode={setDemoMode}
                setShowInfo={setShowInfo}
                loading={loading}
                setLoading={setLoading}
            />
            <Divider sx={{
              padding: '1dvh',
              fontWeight: '600',
              width: '100%',
              m: '20px 0'
            }}>OR</Divider>
            <Button
                onClick={handleDemoMode}
                size={'large'}
                variant='contained'
                disabled={loading}
                sx={{
                  borderRadius: '4px',
                  width: '100%',
                  height: '60px',
                  // maxWidth: '20rem',
                  alignSelf: 'center',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '26px',
                }}
            >
              Try it without code
            </Button>
          </Box>
        </Fade>
      </Modal>
  );
};

export default GreetingModal;
