import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStaffItem } from "../../../redux/slices/cartSlice";
import { Box, Button, Paper, Tab, Tabs, Typography, Zoom } from "@mui/material";
import { grey } from '@mui/material/colors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
            >
              {children}
            </Box>
        )}
      </div>
  );
}

const setDefaultTabs = (currentRate) => {
  const numericRate = Number(currentRate);
  const options = { minimal: 0, 'part-time': 1, 'full-time': 2 };
  return numericRate >= 1 ? options['full-time'] : numericRate >= 0.49 ? options['part-time'] : options['minimal'];
};

function Pricing({ pricing, emp }) {
  const dispatch = useDispatch()
  const selectedStaff = useSelector((state) => state.cartSlice.selectedStaff);
  const { secondaryColor, primaryColor } = useSelector((state) => state.settingsSlice.settings.style.main);

  const {
    priceCoeff
  } = useSelector(state => state.settingsSlice.settings.cardsConfig);

  const [value, setValue] = useState(setDefaultTabs(emp.sum_rate_rh));
  const handleChange = (event, newValue) => setValue(newValue);

  const existingStaff = selectedStaff.find((staff) => staff.employee_id === emp.employee_id);

  const handleBookInterview = () => {
    if (existingStaff) {
      return;
    }
    dispatch(createStaffItem(emp));
  }


  return (
      <Paper
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            overflow: 'hidden',
            height: 'max-content',
          }}
      >
        <Tabs aria-label="tabs" value={value} onChange={handleChange} variant="fullWidth">
          <Tab label={'Minimal'}/>
          <Tab label={'part-time'}/>
          <Tab label={'full-time'}/>
        </Tabs>

        {pricing.length &&
            pricing.map(({ title, value: price, currency, hours, disabled }, index) => (
                <TabPanel key={`${value}${index}`} value={value} index={index}>
                  <Zoom in>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        padding={'1rem 0'}
                    >
                      <Typography
                          variant={'h2'}
                          color={disabled ? grey[400] : "primary"}
                          sx={{
                            '&:first-letter': {
                              fontSize: '.75em',
                              verticalAlign: 'top',
                            },
                            fontVariationSettings: `'wdth' 100, 'wght' 800`,
                          }}
                      >
                        {`${currency}${Math.round(price * priceCoeff)}`}
                      </Typography>
                      <Typography
                          variant='body2'
                          color={disabled ? grey[400] : ''}
                      >{`per month (${hours} hours)`}</Typography>
                      <Typography variant='caption' color={grey[400]}>{`~ ${currency}${(Math.round(price * priceCoeff / hours * 100) / 100).toFixed(
                          2,
                      )} per hour`}</Typography>
                    </Box>
                  </Zoom>
                  <Button
                      variant='contained'
                      fullWidth
                      size='large'
                      onClick={handleBookInterview}
                      color='primary'
                      sx={{
                        borderRadius: 'unset',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '4rem',
                        backgroundImage: existingStaff
                            ? `linear-gradient(109.6deg, ${primaryColor} 11.2%, ${secondaryColor} 91.7%)`
                            : '',
                        fontVariationSettings: `'wdth' 100, 'wght' 800`,
                      }}
                      disabled={disabled ?? false}
                  >
                    <Typography sx={{ fontWeight: 'inherit', fontSize: '1.2rem' }}>
                      {disabled
                          ? 'Option is unavailable now'
                          : `${existingStaff ? `Added to interview list` : `Add to interview list`}`}
                    </Typography>
                  </Button>
                </TabPanel>
            ))}
      </Paper>
  )
}

export { Pricing }
