import { createTheme } from '@mui/material/styles';
import { getContrastColor } from "../helpers";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";

const DynamicTheme = () => {
  const {
    backgroundColor,
    primaryColor,
    secondaryColor,
    borderRadius
  } = useSelector(state => state.settingsSlice.settings.style.main);

  return createTheme({
    palette: {
      background: {
        default: backgroundColor || "rgb(249,251,253)",
      },
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      notification: {
        light: grey[900],
      },
    },
    typography: {
      fontFamily: 'OpenSans-VariableFont, sans-serif',
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius,
          },
        },
      },
      // MuiButtonGroup: {
      //   styleOverrides: {
      //     groupedText: {
      //       borderColor: '#f1f1f1',
      //     },
      //   },
      // },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.severity === "success" && {
              backgroundColor: "#60a5fa",
            }),
            ...(ownerState.severity === "error" && {
              backgroundColor: "#ff554f",
            }),
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius,
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            '& .MuiToggleButton-root.Mui-selected': {
              color: getContrastColor(backgroundColor),
              borderColor: getContrastColor(backgroundColor),
              opacity: '1'
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: '0',
            boxShadow: '0 3px 24px 5px rgba(0,0,0,.1)',
            border: '1px solid #D5E3F3',
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 0,
              '&:last-child': {
                marginBottom: '2rem',
              },
            }

          }
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-content': {
              marginLeft: '.5rem',
              justifyContent: 'space-between',
              '&.Mui-expanded': {
                marginLeft: '.5rem',
              }
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '1rem',
            borderTop: '1px solid rgba(0, 0, 0, .125)',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#7098D4',
            '&.Mui-selected': {
              color: '#2196F3',
            },
            '&.Mui-disabled': {
              color: '#D5E3F3',
            }
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: 0,
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: '#2196F3',
          },
        },
      },
      // MuiFormLabel: {
      //   styleOverrides: {
      //     root: {
      //       color: '#8C95C1'
      //     }
      //   }
      // },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#1677FF',
            }
          }
        }
      }
    }
  });
}
export default DynamicTheme;
