import React, { memo, useRef, useState } from 'react';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import { Typography, styled } from "@mui/material";
import { editMain } from "../../../../redux/slices/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const StyledInputRoot = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #D5E3F3;
  height: 32px;
`;

const StyledInput = styled('input')`
  outline: none;
  min-width: 0;
  width: 2rem;
  text-align: center;
  border: none;

  &:focus-visible {
    outline: none;
  }
`;

const StyledButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 100%;
  border: none;
  background-color: white;
  color: #CCCCCC;
  transition: all 120ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    cursor: pointer;
    background: #C4EAFF;
    color: white;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
    border-left: 1px solid #D5E3F3;
  }

  &.decrement {
    border-right: 1px solid #D5E3F3;
  }
`;

const BorderRadiusInput = memo(({ disabled }) => {
  const dispatch = useDispatch()
  const { borderRadius } = useSelector(state => state.settingsSlice.settings.style.main);
  const inputRef = useRef(null);
  const initBR = () => {
    const match = borderRadius.match(/^([\d.]+)(\w*)$/);
    return match ? +match[1] : 0;
  };

  const [radiusValue, setRadiusValue] = useState(initBR);

  const handleChange = (event) => {
    let value = event.target.value;
    if (!value || isNaN(value)) return;
    value = Math.max(0, +value); // Ensure value is non-negative
    setRadiusValue(value);
    dispatch(editMain({ name: "borderRadius", value: `${value}px` }));
  };

  const handleIncrement = () => {
    const newValue = radiusValue + 1;
    setRadiusValue(newValue);
    dispatch(editMain({ name: "borderRadius", value: `${newValue}px` }));
  };

  const handleDecrement = () => {
    if (radiusValue > 0) {
      const newValue = radiusValue - 1;
      setRadiusValue(newValue);
      dispatch(editMain({ name: "borderRadius", value: `${newValue}px` }));
    }
  };

  return (
    <fieldset style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      border: 'solid 1px #D5E3F3',
      padding: '0 12px 10px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      height: '65px',
      flex: 1,
      '&:hover': {
        border: 'solid 1px BlueViolet',
      }
    }}>
      <legend style={{
        fontSize: '12px',
        padding: '0 5px',
        maxWidth: 'calc(144% - 32px)',
        overflow: 'hidden',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        marginLeft: '-5px',
        color: '#8C95C1',
        '&:hover': {
          color: 'BlueViolet',
        }
      }}>Border Radius</legend>
      <BaseNumberInput
        ref={inputRef}
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" onClick={handleIncrement} />,
            className: 'increment',
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" onClick={handleDecrement} />,
            className: 'decrement',
            disabled: radiusValue <= 0
          },
        }}
        aria-label="Quantity Input"
        min={0}
        disabled={disabled}
        // id="outlined-adornment-radius"
        // size="small"
        // type={'number'}
        name="borderRadius"
        value={radiusValue}
        onChange={handleChange}
        label="Border Radius"
      // sx={{
      //   flex: 1,
      // }}
      />
      <Typography sx={{ color: '#000000', alignSelf: 'center' }}>
        px
      </Typography>
    </fieldset>
  )
})

export default BorderRadiusInput;
