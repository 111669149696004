import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import mainLogo from "../../assets/logo_svg_full.svg";

const initialState = {
  clientInfo: {
    companyName: '',
  },
  isLoading: false,
  error: null,
  message: null,
  settings: {
    style: {
      main: {
        backgroundColor: '#F9FBFD',
        // backgroundColor: '#ee15ee',
        primaryColor: '#1677FF',
        secondaryColor: '#ff7807',
        borderRadius: '10px',
        logo: mainLogo
      },
      card: {
        avatarBackground: '#1677FF',
        cvAvatarBackground: '#1677FF',
        chipBorderColor: '#1677FF',
        chipTextColor: '#1677FF',
      },
      footer: {
        filterBtnBGColor: '#DF6703',
      },
    },
    filter: {
      department: [],
      position: [],
      language: []
    },
    cardsConfig: {
      priceCoeff: 1
    },
  }
};


export const getSettings = createAsyncThunk(
    "settings/fetchSettings",
    async (customersAffiliateCode) => {
      try {
        const { data } = await axios({
          url: `https://crm.rhelpers.com/api/v1/get-settings-module/${customersAffiliateCode}`,
        });
        if(Array.isArray(data.data)) {
          throw new Error(data.message);
        }
        const rawData = data.data.moduleSettings;
        const cleanedData = rawData.replace(/\\/g, '');
        return {
          settings: JSON.parse(cleanedData),
          affiliateCode: customersAffiliateCode,
          clientInfo: data.data['client_info']['company_name'],
          message: data.message
        };
      } catch (error) {
        console.error('Error fetching settings:', error);
        throw error;
      }
    }
);

export const saveSettings = createAsyncThunk(
    "settings/saveSettings",
    async ({ settings, affiliateCode }) => {
      try {
        const { data } = await axios({
          url: `https://crm.rhelpers.com/api/v1/update-settings-module/${affiliateCode}`,
          method: 'POST',
          params: {
            settings_module: JSON.stringify(settings)
          }
        });
        if(Array.isArray(data.data)) {
          throw new Error(data.message);
        }
        return data.message;
      } catch (error) {
        console.error('Error fetching settings:', error);
        throw error;
      }
    }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    editMain: (state, action) => {
      state.settings.style.main[action.payload.name] = action.payload.value
    },
    editCard: (state, action) => {
      state.settings.style.card[action.payload.name] = action.payload.value
    },

    editFooter: (state, action) => {
      state.settings.style.footer[action.payload.name] = action.payload.value
    },
    editCoeff: (state, action) => {
      state.settings.cardsConfig[action.payload.name] = action.payload.value
    },
    editFilter: (state, action) => {
      state.settings.filter[action.payload.name] = action.payload.value;
    },
    resetSettings: (state) => {
      return { ...initialState, affiliateCode: state.affiliateCode, clientInfo: state.clientInfo };
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getSettings.pending, (state) => {
          state.error = null;
          state.isLoading = true;
          state.message = null;
        })
        .addCase(getSettings.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = null;
          state.settings = action.payload.settings;
          state.clientInfo.companyName = action.payload.clientInfo;
          state.message = action.payload.message;
          // saveAffiliateStatus(action.payload.affiliateCode);
        })
        .addCase(getSettings.rejected, (state, action) => {
          state.error = action.error.message;
          state.isLoading = false;
        })
        .addCase(saveSettings.pending, (state) => {
          state.error = null;
          state.isLoading = true;
        })
        .addCase(saveSettings.fulfilled, (state, action) => {
          state.message = action.payload;
          state.isLoading = false;
          state.error = null;
        })
        .addCase(saveSettings.rejected, (state, action) => {
          state.error = action.error.message;
          state.isLoading = false;
        })
  },
});
export const {
  editMain,
  editCard,
  editCoeff,
  editFilter,
  resetSettings,
  editFooter,
  editShadow,
  retrieveStyle,
} = settingsSlice.actions;

export default settingsSlice.reducer;
