import ReactPlayer from 'react-player'
import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { Logo } from "../Logo";

export default function Player({ url, defaultUrl, isVideoVertical, isMobile }) {
  const matches = useMediaQuery('(min-width:679px)');
  const renderData = (
      <Paper
          sx={{
            display: 'flex',
            overflow: 'hidden',
            minWidth: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            aspectRatio: `${url ? 'unset' : '16/9'}`,
            backgroundColor: '#f1f1f1',
          }}
      >
        {url || defaultUrl ? (
            <ReactPlayer width={'100%'} height={'100%'} controls={true} style={{ aspectRatio: isVideoVertical ? '9 / 16' : '16 / 9' }} url={url ? url : defaultUrl} />
        ) : (
            <Stack
                sx={{
                  flexFlow: 'column nowrap',
                  gap: '2.5rem',
                  alignItems: 'center',
                }}
            >
              <Logo version='full' size={1} />
              <Typography variant={'h6'}>video almost here 😉</Typography>
            </Stack>
        )}
      </Paper>
  );
  return isMobile? !matches ? renderData : null : matches ? renderData : null;
}
