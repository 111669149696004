import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpened: false,
  message: null,
  status: null
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    closeNotification: state => {
      state.isOpened = false;
    },
    openNotification: (state, { payload }) => {
      state.isOpened = true;
      state.message = payload.message;
      state.status = payload.status;
    },
  },
});

export const {
  closeNotification,
  openNotification
} = notificationSlice.actions;
export default notificationSlice.reducer;
