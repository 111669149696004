import React from 'react';
import SkeletonMain from "./components/SkeletonMain";
import SkeletonCV from "./components/SkeletonCV";
import { useLocation } from "react-router-dom";

const StyledSkeleton = ({ small }) => {
  const location = useLocation();

  return (
      <>
        {location.pathname === '/' ? <SkeletonMain small={small}/>
            : <SkeletonCV/>}
      </>
  );
};

export default StyledSkeleton;
