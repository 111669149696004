import { Box, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import ResponsibilityItem from "./ResponsibilityItem";
import { getContrastColor } from "../../../lib/helpers";
import { useSelector } from "react-redux";

export default function Responsibilities({ workExperiences, isMobile }) {
  const matches = useMediaQuery('(min-width:679px)');
  const {
    secondaryColor
  } = useSelector(state => state.settingsSlice.settings.style.main);

  const allResponsibilities = new Map();

  workExperiences.forEach(experience =>
      experience.responsibilities_object?.forEach(el =>
          !allResponsibilities.has(el.name) && allResponsibilities.set(el.name, el.description)
      )
  );
  const allResponsibilitiesNames = [...allResponsibilities.keys()];

  const renderData = allResponsibilities.size ? (
      <Box
          sx={{
            width: '100%',
            minWidth: '280px',
          }}
      >
        <Paper
            sx={{
              height: '100%',
              display: 'flex',
              flexFlow: 'column nowrap',
              fontFamily: 'unset',
              overflow: 'hidden',
            }}
        >
          <Typography
              variant={"button"}
              sx={{
                backgroundColor: secondaryColor || '#9c27b0',
                padding: ".7rem 1rem",
                color: getContrastColor(secondaryColor || '#9c27b0'),
              }}>
            Key responsibilities
          </Typography>
          <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={'.5rem'}
              padding={'1rem'}
          >
            {allResponsibilitiesNames.map((responsibilityName) => <ResponsibilityItem key={responsibilityName}
                                                                                      responsibilityName={responsibilityName}
                                                                                      responsibilityDescription={allResponsibilities.get(responsibilityName)}/>)}
          </Stack>
        </Paper>
      </Box>
  ) : null;
  return isMobile ? !matches ? renderData : null : matches ? renderData : null;
}
