import { Box, Button, Stack, Typography } from "@mui/material";
// import { Logo } from "../components/Logo";
import { getContrastColor } from "../lib/helpers";
import { useSelector } from "react-redux";
import ChromeDinoGame from 'react-chrome-dino';

function NothingFoundPage() {
  const {
    backgroundColor
  } = useSelector(state => state.settingsSlice.settings.style.main);

  return (
      <Stack sx={{
        width: '100%',
        flexGrow: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column nowrap',
        gap: '3rem',
        alignSelf: 'center',
        height: 'calc(100vh - 6rem)'
      }}>
        {/*<Logo size={'s'}/>*/}
        <Typography variant={'h1'} color='primary' fontWeight={'bold'} sx={{fontSize: 'clamp(4rem, 8vw, 8rem)'}}>404</Typography>

        <Box sx={{width: 'clamp(360px, 100vw, 600px)', height:'200px'}}>
          <ChromeDinoGame />
        </Box>
        <Box style={{
          color: getContrastColor(backgroundColor)
        }}>
          Nothing found :( Try again starting {" "}
          <Button
              component={'a'}
              href={"/"}
              sx={{
                display: "inline",
                color: getContrastColor(backgroundColor),
                textDecoration: "underline",
                cursor: "pointer",
              }}>
            Homepage
          </Button>
        </Box>
      </Stack>
  )
}

export { NothingFoundPage }
