import {
  Box, Button,
  Typography, Avatar,
  IconButton,
  Divider,
  Zoom,
  Fab,
  Collapse,
  Drawer,
} from "@mui/material";
import React, { useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSettings, saveSettings,
} from "../../redux/slices/settingsSlice";
import CachedIcon from "@mui/icons-material/Cached";
import SaveIcon from '@mui/icons-material/Save';
import { Link } from "react-router-dom";
import FormDrawer from "./components/FormDrawer";
import { reset } from "../../redux/slices/filterSlice";
import { LoadingButton } from "@mui/lab";
import { Notification } from "../Notification/Notification";
import { openNotification } from "../../redux/slices/notificationSlice";
import ResetBtn from "./components/ResetBtn/ResetBtn";
import { stringAvatar } from "../../lib/helpers";
import GreetingModal from "../Modals/GreetingModal";
import OnSaveModal from "../Modals/OnSaveModal";
import SettingsTabs from "./components/SettingsTabs/SettingsTabs";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import InfoModal from "../Modals/InfoModal";


const ModuleSettings = memo(({ view }) => {
  const dispatch = useDispatch()

  const [formOpen, setFormOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [demoMode, setDemoMode] = useState(!!localStorage.getItem('affiliateCode') || !!sessionStorage.getItem('demoMode'))
  const { settings, isLoading, clientInfo } = useSelector(state => state.settingsSlice);
  const [showInfo, setShowInfo] = useState(demoMode);
  const [showExamplesModal, setShowExamplesModal] = useState(false);
  const [showSettingsText, setShowSettingsText] = useState(false);

  const affiliateCode = localStorage.getItem('affiliateCode') || ''
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!open) {
      setTimeout(() => setShowSettingsText(false), 3000);
    }
  }, [open])

  const handleSave = () => {
    if (!!affiliateCode) {
      dispatch(saveSettings({ settings, affiliateCode }))
        .then((response) => {
          if (response.error) {
            dispatch(openNotification({ message: response.error.message, status: 'error' }));
          } else {
            dispatch(openNotification({ message: response.payload, status: 'success' }));
            setSaveModalOpen(true)
          }
        })
    } else {
      setFormOpen(!formOpen)
    }
  };

  const handleReset = () => {
    dispatch(openNotification({ message: "Settings have been reset successfully", status: 'success' }));
    dispatch(resetSettings())
    dispatch(reset())
  };

  const handleButtonClick = async (buttonName) => {
    setClickedButton(buttonName)
    if (buttonName === 'reset') {
      await handleReset()
    } else if (buttonName === 'save') {
      await handleSave()
    }
  }

  return (
    <>
      <IconButton
        size="large"
        onClick={() => {
          setShowSettingsText(true);
          setOpen(false);
        }}
        sx={{
          position: view ? 'absolute' : 'fixed',
          top: '13px',
          left: !view ? '' : open ? '403px' : '-100%',
          right: view ? '' : open ? '0' : '100%',
          transform: 'translateX(-50%)',
          zIndex: 1201,
          backgroundColor: '#1677FF',
          transition: 'all 195ms cubic-bezier(0, 0, 0.2, 1);',
          '&:hover': {
            backgroundColor: 'rgba(96, 165, 250)',
          },
        }}
      >
        <ArrowBackIosRoundedIcon sx={{ color: 'white' }} fontSize={'small'} />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        variant={view ? "persistent" : "temporary"}
        PaperProps={{
          style: {
            width: view ? '403px' : '100%',
            height: view && '100vh',
            backgroundColor: '#ffffff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '27px 1rem 39px',
            overflowY: 'auto',
            borderRadius: 0,
            zIndex: 11,
            position: view ? 'fixed' : 'relative',
            top: 0,
            left: 0,
            bottom: 0,
          },
        }}
      >
        <Notification />
        {!demoMode && (
          <GreetingModal
            setFormOpen={setFormOpen}
            view={view}
            demoMode={demoMode}
            setDemoMode={setDemoMode}
            setShowInfo={setShowInfo}
          />
        )}
        <Box sx={{
          width: '100%',
        }}>
          {affiliateCode ? clientInfo.companyName && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '18px',
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '17px',
                pt: '12px',
              }}>
                <Avatar {...stringAvatar(clientInfo.companyName)} />
                <Typography
                  variant='body1'
                  sx={{
                    color: '#292929',
                  }}
                >
                  {clientInfo.companyName}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{
              display: 'flex',
              gap: '.5rem',
              marginBottom: '24px',
              width: view ? '95%' : 'calc(100% - 50px)',
            }}>
              <InfoOutlinedIcon sx={{ color: '#1677FF' }} />
              <Typography
                variant={'subtitle2'}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#292929',
                }}
              >
                {`To unblock all settings and start creating a module's unique style for your site, just click `}
                <Link onClick={() => setFormOpen(!formOpen)} to={''}
                  style={{ fontWeight: 'bold', color: '#2196F3' }}> here to become our
                  affiliate</Link>
              </Typography>
            </Box>
          )}
        </Box>
        {showInfo && <InfoModal showInfo={showInfo} setShowInfo={setShowInfo} view={view} />}
        <Divider sx={{ width: '100%', marginBottom: '10px' }} />
        <SettingsTabs isDemoMode={demoMode} />
        {/* {affiliateCode && ( */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginTop: 'auto',
            marginBottom: '35px',
          }}>
            <InfoOutlinedIcon sx={{ color: '#1677FF' }} />
            <Typography
              component={'span'}
              sx={{
                fontWeight: 600,
                color: '#22457B',
              }}
            >
              {`Click here to get your `}
              <Button
                onClick={() => setSaveModalOpen(true)}
                variant="text"
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '21.79px',
                  p: 0,
                  color: '#2196F3',
                  textDecoration: 'underline',
                  display: 'inline',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                SCRIPT
              </Button>
            </Typography>
          </Box>
        {/* )} */}
        {/* {affiliateCode && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '35px',
          }}>
            <InfoOutlinedIcon sx={{ color: '#1677FF' }} />
            <Typography
              component={'span'}
              sx={{
                fontWeight: 600,
                color: '#22457B',
              }}
            >
              {`Click here to see `}
              <Button
                onClick={() => setShowExamplesModal(true)}
                variant="text"
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '21.79px',
                  p: 0,
                  color: '#2196F3',
                  textDecoration: 'underline',
                  display: 'inline',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                EXAMPLE
              </Button>
            </Typography>
          </Box>
        )} */}
        <Box sx={{
          width: '100%',
          display: !demoMode ? 'none' : 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'end',
          gap: '15px',
          justifyContent: 'space-between',
          marginTop: !affiliateCode && 'auto',
        }}>
          {!affiliateCode ? (
            <Button
              onClick={() => handleButtonClick('reset')}
              variant="outlined"
              endIcon={<CachedIcon />}
              size="large"
              sx={{
                width: '100%',
                height: '60px',
                border: '1px solid rgba(22, 119, 255, 0.5)',
                fontSize: '16px',
                lineHeight: '26px',
                color: '#1677FF',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(22, 119, 255, 0.04)',
                  border: '1px solid #1677FF',
                },
              }}
            >
              Reset
            </Button>
          ) : (
            <ResetBtn />
          )}
          <LoadingButton
            sx={{
              backgroundColor: '#1677FF',
              '&:hover': {
                backgroundColor: 'rgb(15, 83, 178)',
                boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
              },
              height: '60px',
              width: '100%',
            }}
            loading={isLoading && clickedButton === 'save'}
            variant="contained"
            size="large"
            onClick={() => handleButtonClick('save')}
            endIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Box>
      </Drawer>

      {formOpen && (
        <FormDrawer
          view={view}
          formOpen={formOpen}
          setFormOpen={setFormOpen}
          setDemoMode={setDemoMode}
          setShowInfo={setShowInfo}
        />
      )}

      <OnSaveModal setSaveModalOpen={setSaveModalOpen} saveModalOpen={saveModalOpen} view={view} />

      <Zoom in style={{ transitionDelay: '1000ms' }}>
        <Fab
          size={'large'}
          variant={'extended'}
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            position: 'fixed',
            top: '13px',
            left: '3%',
            zIndex: 1,
            backgroundColor: '#1677FF',
            transition: 'backgroundColor 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(96, 165, 250)',
            },
            padding: showSettingsText ? '0 1rem' : '0',
          }}
        >
          <SettingsOutlinedIcon sx={{ color: 'white' }} fontSize={'small'} />
          <Collapse in={showSettingsText} sx={{ pl: showSettingsText ? '.5rem' : 0, color: 'white' }} orientation='horizontal'>
            Settings
          </Collapse>
        </Fab>
      </Zoom>
    </>
  )
})

export { ModuleSettings }
