import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  Fade,
  IconButton, List, ListItem,
  Modal, Tooltip,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

const OnSaveModal = ({ view, saveModalOpen, setSaveModalOpen }) => {

  const affiliateCode = localStorage.getItem('affiliateCode');

  const scriptCode = !!affiliateCode ? `<script 
  src="https://module.rhelpers.com/iframeScript.js"
  data-affiliate-code="${affiliateCode}"
  data-iframe-placement-id="myContainer"
  id="rh-module-script"
></script>` : `<script 
  src="https://module.rhelpers.com/iframeScript.js"
  data-iframe-placement-id="myContainer"
  id="rh-module-script"
></script>`

  const [copiedCodeBoxes, setCopiedCodeBoxes] = useState([false, false]);

  const handleCopy = (index) => {
    navigator.clipboard.writeText(index === 0 ? scriptCode : '<div id="myContainer"></div>')
        .then(() => {
          setCopiedCodeBoxes(prevStates => {
            const updatedStates = [...prevStates];
            updatedStates[index] = true;
            return updatedStates;
          });
          setTimeout(() => {
            setCopiedCodeBoxes(prevStates => prevStates.map(copied => false));
          }, 2000);
        })
        .catch((error) => {
          console.error('Could not copy text: ', error);
        });
  };

  return (
      <Modal
          key={view}
          open={saveModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          onClose={() => setSaveModalOpen(false)}
          disableScrollLock={view}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
      >
        <Fade in={saveModalOpen}>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                '@media (max-width: 600px)': {
                  width: '90%',
                },
                backgroundColor: 'white',
                '&:focus': {
                  outline: 'none'
                },
                boxShadow: 24,
                p: 4,
                borderRadius: '4px',
                overflowY: 'auto',
                maxHeight: '90vh',
                // maxWidth: '90vw',
                maxWidth: '1122px',
              }}
          >
            <IconButton
                aria-label='close'
                onClick={() => setSaveModalOpen(false)}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  zIndex: 2,
                }}
            >
              <CloseIcon/>
            </IconButton>
            <Typography
                variant={'subtitle1'}
                fontWeight={'bold'}
                textAlign={'center'}
                gutterBottom
                sx={{
                  textWrap: 'balance',
                }}
            >
              {`Instructions for Adding an iframe Module to Your Website`}
            </Typography>
            <List
                sx={{
                  width: '100%',
                  backgroundColor: 'background.paper',
                  listStyle: "decimal",
                  fontWeight: 'bold',
                  fontSize: '14px',
                  paddingLeft: '20px',
                }}
                aria-labelledby="nested-list-subheader"
            >
              <ListItem sx={{ display: "list-item" }}>
                <Typography
                    variant={'subtitle2'}
                    fontWeight={'bold'}
                    gutterBottom
                    sx={{
                      textWrap: 'balance',
                    }}
                >
                  {`Copy the following code:`}
                </Typography>

                <Box component="pre"
                     sx={{ position: 'relative', backgroundColor: 'grey.100', p: 2, borderRadius: 1, }}>
                  <Typography component="code" fontSize={'small'} sx={{
                    display: 'block',
                    minWidth: '100px', overflow: 'auto',
                  }}>{scriptCode}</Typography>

                  <Tooltip
                      placement="left"
                      title={'copy'}
                  >
                    <IconButton aria-label='copy-script'
                                size={'small'}
                                onClick={() => handleCopy(0)}
                                sx={{
                                  color: (theme) => theme.palette.grey[500],
                                  backgroundColor: 'grey.100',
                                  position: 'absolute',
                                  top: 8,
                                  right: 8,
                                  borderRadius: 1
                                }}>
                      {!copiedCodeBoxes[0] ?
                          <ContentCopyIcon fontSize={'small'} sx={{ transform: 'rotateX(180deg)' }}/> :
                          <LibraryAddCheckIcon fontSize={'small'}/>}
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <>
                  <Typography
                      variant={'subtitle2'}
                      component={'div'}
                      gutterBottom
                      // sx={{
                      //   textWrap: 'balance',
                      // }}
                  >
                    <Box component="span" fontWeight='bold'>Add a container with ` id="myContainer" ` to your website
                      where the iframe will be inserted. </Box> If the container has other elements, the iframe will be
                    inserted as the last element in the container.
                  </Typography>
                  <Typography
                      variant={'subtitle2'}
                      gutterBottom
                      sx={{
                        textWrap: 'balance',
                        paddingTop: '2dvh'
                      }}
                  >
                    {`Example code to add the container:`}
                  </Typography>
                </>
                <Box component="pre"
                     sx={{
                       position: 'relative',
                       backgroundColor: 'grey.100',
                       p: 2,
                       borderRadius: 1,
                       minWidth: '200px',
                       overflow: 'auto'
                     }}>
                  <Typography component="code" fontSize={'small'} sx={{
                    display: 'block',
                    minWidth: '100px', overflow: 'auto',
                  }}>{'<div id="myContainer"></div>'}</Typography>
                  <Tooltip placement="left" title={'copy'}>
                    <IconButton aria-label='copy-instance'
                                size={'small'}
                                onClick={() => handleCopy(1)}
                                sx={{
                                  color: (theme) => theme.palette.grey[500],
                                  backgroundColor: 'grey.100',
                                  position: 'absolute',
                                  top: 8,
                                  right: 8,
                                  borderRadius: 1
                                }}>
                      {!copiedCodeBoxes[1] ?
                          <ContentCopyIcon fontSize={'small'} sx={{ transform: 'rotateX(180deg)' }}/> :
                          <LibraryAddCheckIcon fontSize={'small'}/>}
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
            </List>
            <Typography
                variant={'body2'}
                component='div'
                // fontWeight={'bold'}
                // textAlign={'center'}
                gutterBottom
                sx={{
                  marginBottom: '2dvh',
                  paddingTop: '2dvh'
                }}
            >
              <Box component="span" fontWeight='bold'>Note: </Box> If you do not add a container with <Box
                component="span" fontWeight='bold'>` id="myContainer" ` </Box> to your HTML code, the module will be
              inserted at the end of the <Box component="span" fontWeight='bold'>` body ` </Box>.
            </Typography>

            <Typography
                variant={'body2'}
                component='div'
                // fontWeight={'bold'}

                gutterBottom
                sx={{
                  marginBottom: '2dvh',
                }}
            >
              If you wish, you can change the ID where you intend to insert the code to your own preference.
              In that case, update the value in <Box component="span" fontWeight='bold'>` data-iframe-placemante-id
              ` </Box> to match the new ID,
              and ensure that a container with the specified ID is placed accordingly.
            </Typography>

          </Box>
        </Fade>
      </Modal>
  );
};

export default OnSaveModal;
