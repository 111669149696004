import React, { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CachedIcon from "@mui/icons-material/Cached";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, resetSettings } from "../../../../redux/slices/settingsSlice";
import { openNotification } from "../../../../redux/slices/notificationSlice";
import { reset } from "../../../../redux/slices/filterSlice";

const ResetBtn = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false)
  const [factoryReset, setFactoryReset] = useState(false)
  const resetBtnRef = useRef(null);
  const dialogRef = useRef(null);

  const { isLoading } = useSelector(state => state.settingsSlice)

  const affiliateCode = localStorage.getItem('affiliateCode') || ''

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (resetBtnRef.current && resetBtnRef.current.contains(event.target)) {
      return;
    }
    if (dialogRef.current && dialogRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = (event, isFactory) => {
    if (resetBtnRef.current && resetBtnRef.current.contains(event.target)) {
      return;
    }
    setFactoryReset(isFactory);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const resetHandler = () => {
    if (!factoryReset) {
      dispatch(getSettings(affiliateCode))
          .then((response) => {
            setDialogOpen(false);
            setOpen(false)
            if (response.error) {
              dispatch(openNotification({ message: response.error.message, status: 'error' }));
            } else {
              dispatch(openNotification({ message: "Settings have been reset successfully", status: 'success' }));
            }
          })
    } else {
      dispatch(openNotification({ message: "Settings have been reset successfully", status: 'success' }));
      dispatch(resetSettings())
      dispatch(reset())
      setDialogOpen(false);
      setOpen(false)
    }

  };


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      resetBtnRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
      <>
        <Button
            ref={resetBtnRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            variant="outlined"
            // color="error"
            endIcon={<CachedIcon/>}
            onClick={handleToggle}
            size="large"
            sx={{
              // width: '45%',
              width: '100%',
              height: '60px',
              border: '1px solid rgba(22, 119, 255, 0.5)',
              fontSize: '16px',
              lineHeight: '26px',
              backgroundColor: 'white',
              color: '#1677FF',
              '&:hover': {
                backgroundColor: 'rgba(22, 119, 255, 0.04)',
                border: '1px solid #1677FF',
              },
              // backgroundColor: 'rgba(96,165,250,0.1)',
              // border: `1px solid rgba(96,165,250,0.4)`,
              // '&:hover': {
              //   backgroundColor: 'rgba(96,165,250,0.1)',
              //   border: '1px solid rgba(96,165,250,0.8)',
              // },
            }}
        >
          Reset
        </Button>
        <Popper
            open={open}
            anchorEl={resetBtnRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal

        >
          {({ TransitionProps, placement }) => (
              <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
              >
                <Paper sx={{ borderRadius: 0 }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                        sx={{
                          marginBottom: '1rem',
                        }}
                    >
                      <MenuItem onClick={(e) => handleClick(e, true)}>Default settings</MenuItem>
                      <MenuItem onClick={(e) => handleClick(e, false)}>User's default settings</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
          )}
        </Popper>

        <Dialog
            ref={dialogRef}
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
              borderRadius: '4px',
            }}
        >
          <DialogTitle id="responsive-dialog-title">
            {factoryReset
                ? "Are you sure you want to reset settings to default settings?"
                : "Are you sure you want to reset settings to user defaults?"
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {factoryReset
                  ? "All settings will be reset to default settings. This cannot be undone."
                  : "All settings will be reset to user defaults. This cannot be undone."
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
                autoFocus
                onClick={handleDialogClose}
                sx={{
                  color: '#60a5fa',
                  '&:hover': {
                    backgroundColor: 'rgba(96,165,250,0.04)',
                  },
                }}
            >
              Disagree
            </Button>
            <LoadingButton
                onClick={resetHandler}
                loading={isLoading}
                sx={{
                  color: '#60a5fa',
                  '&:hover': {
                    backgroundColor: 'rgba(96,165,250,0.04)',
                  },
                }}
            >
              Agree
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default ResetBtn;
