import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { storeIDs } from "../../../../lib/mapData";
import {
  handleFilterValues,
  setIndustriesIds,
} from "../../../../redux/slices/filterSlice";
import { editFilter } from "../../../../redux/slices/settingsSlice";

export function Filter({ id, values, disabled = false }) {
  const dispatch = useDispatch();
  const { department_ids } = useSelector((store) => store.filterSlice);
  const store = useSelector((store) => store.filterSlice);

  const filterOptions = useMemo(() => {
    const newOptions = [];

    // If the user has selected any category of employees,
    // then the list of employee positions is reduced to the selected category
    if ((id === "pos") && department_ids.length > 0) {
      const newValues = values.filter((v) =>
          department_ids.includes(Number(v.department_id))
      );
      for (const v of newValues) {
        newOptions.push({
          ...v,
          label: v.name,
          value: Number(v.id),
        });
      }
    } else {
      // Normal display of all select values
      for (const v of values) {
        newOptions.push({
          ...v,
          label: v.name,
          value: Number(v.id),
        });
      }
    }
    return newOptions;
  }, [department_ids, id, values]);

  // Method for writing selected values to store
  const handleChange = (event, values) => {
    const action = storeIDs.find((a) => a.id === id).filterAction;
    const newValuesArr = values.map((item) => item.value);
    dispatch(action(newValuesArr));
    const idArr = values.map(v => Number(v.id))

    if (id === 'industries') {
      dispatch(setIndustriesIds(idArr))
    }

    dispatch(handleFilterValues({ id, values }))
    // dispatch(editFilter({ name: id, value: values.map(v => Number(v.id)) }));
  }

  return (
      <Autocomplete multiple fullWidth limitTags={3} options={filterOptions}
                    value={filterOptions.filter((opt) =>
                        store[storeIDs.find((sId) => sId.id === id).value].includes(opt.value)
                    )}
                    renderOption={(props, option) => {
                      return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                      );
                    }}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                          <Chip
                              {...getTagProps({ index })}
                              key={option.id}
                              label={option.label}
                          />
                      ));
                    }}
                    onChange={handleChange}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={storeIDs.find((sId) => sId.id === id).placeholder}
                        />
                    )}
                    size={'small'}
                    disabled={disabled}
      />
  );
}
