import {
  Box,
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey } from "@mui/material/colors";

export default function Education({ item }) {
  const {
    additional,
    comment,
    degree_name,
    education_tags,
    end_date_unix,
    institution_name,
    specialisation,
    start_date_unix,
  } = item;

  const [open, setOpen] = useState(false);
  const startDate = new Date(start_date_unix * 1000).toLocaleDateString("default", {
    year: "numeric",
    month: "2-digit",
  });
  const endDate = end_date_unix
      ? new Date(end_date_unix * 1000).toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
      })
      : "Present";
  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)} selected={open}>
          <ListItemIcon>
            <SchoolIcon/>
          </ListItemIcon>
          <ListItemText sx={{ display: 'flex', flexFlow: 'column nowrap', gap: '.5rem' }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <Box
                              sx={{
                                fontWeight: "600",
                                fontVariationSettings: `'wdth' 80`,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1dvw'
                              }}>
                            {institution_name}
                            {!!+additional ? <Chip color={'primary'} variant={'outlined'} size={'small'}
                                                   label={'Additional'}/> : null}
                          </Box>
                        }
                        secondary={
                          <Stack sx={{
                            flexFlow: "row nowrap",
                            gap: "1rem",
                          }}>
                            <Stack sx={{
                              flexFlow: "row wrap",
                              alignItems: "center",
                              gap: ".3rem 1rem",
                            }}>
                              {`${specialisation} - ${degree_name}`}
                              <Chip
                                  icon={<AccessTimeIcon/>}
                                  label={`${startDate} - ${endDate}`}
                                  size={"small"}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: grey[50],
                                  }}
                              />
                            </Stack>
                          </Stack>
                        }
          />
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemText
                primaryTypographyProps={{ component: "div" }}
                secondaryTypographyProps={{ component: "div" }}
                primary={<Typography
                    variant={"body2"}
                    component={'div'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'1dvh'}
                    lineHeight={'1.5'}
                    dangerouslySetInnerHTML={{ __html: comment }}/>}
                secondary={
                  <Stack sx={{
                    flexFlow: "row wrap",
                    gap: "1rem",
                    padding: ".5rem 0",
                  }}>
                    <Stack sx={{
                      width: '100%',
                      display: 'inline-flex',
                      flexFlow: 'row wrap',
                      gap: '.5dvh',
                      padding: '1dvh',
                      border: '1px solid rgba(25, 118, 210, 0.08)',
                      borderRadius: ' 1rem',
                    }}>
                      <Typography fontWeight={'bold'} color={grey[400]}> Education tags:</Typography>
                      <Stack sx={{
                        flexFlow: 'row wrap',
                        gap: '1dvh',
                      }}>
                        {education_tags.map((tag) => (
                            <Chip label={tag} key={tag} size={"small"} color={'primary'} variant={'outlined'}/>))}
                      </Stack>
                    </Stack>
                  </Stack>
                }
                sx={{
                  padding: "1rem",
                }}
            />
          </List>
        </Collapse>
      </>
  );
}
