import { isEmail, isMobilePhone } from 'validator';

export function validateOrderData(type, value) {
  if (type === 'name' && (value.length < 2 || value.length > 50)) {
    return 'Please, write correct name';
  }
  if (type === 'phone' && value.length > 4) {
    const cleanedPhoneNumber = value.replace(/[\+\s]/g, '');
    if (!isMobilePhone(cleanedPhoneNumber, 'any', { strictMode: false })) {
      return 'Please, write valid phone number';
    }
  }
  if (type === 'email' && !isEmail(value)) {
    return 'Please, write valid email';
  }
  if (type === 'note') {
    const clearedMessage = value.trim();
    if (clearedMessage.length < 2) {
      return 'Please, write valid message';
    }
  }
  if (type === 'checked' && value === 'off') {
    return 'Please, check this field';
  }
  if (type === 'affiliateCode' && (value.length <= 0 || value.length > 12)) {
      return 'Please enter a valid affiliate code';
  }
}

export function isFormValid(values, errors) {
  let result = true;
  const emptyMessage = 'Please, fill up this field';
  for (const key of Object.keys(values)) {
    if (key === 'phone' && values.phone.length <= 4) {
      errors.phone = emptyMessage;
      result = false;
      continue;
    }
    if (key === 'checked' && !values.checked) {
      errors.checked = 'This field is required';
      result = false;
      continue;
    }
    if (!values[key]) {
      errors[key] = emptyMessage;
      result = false;
    }
  }
  if (result) {
    for (const error of Object.values(errors)) {
      if (error) {
        result = false;
        break;
      }
    }
  }
  return { result, errors };
}
