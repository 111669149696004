import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import React from "react";
import { store } from "./redux/store";


import { Provider } from "react-redux";

import "./index.css";
import ThemeRegistry from "./lib/ThemeRegistry/ThemeRegistry";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
      <Provider store={store}>
        <ThemeRegistry>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </ThemeRegistry>
      </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
