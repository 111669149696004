import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeCard } from "../components/Card";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup, Tooltip,
} from "@mui/material";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';
import {
  handleFilterTotal, handleFilterValues, setDepartmentIds, setFilteredDepartments, setFilteredIndustries,
  setFilteredLangs, setFilteredPositions,
  setFilteredSkills,
  setIsFiltered,
  setLangIds,
  setMaxRate,
  setPositionIds
} from "../redux/slices/filterSlice";
import { getContrastColor, getMaxRate } from "../lib/helpers";
import StyledSkeleton from "../components/Skeleton";

function HomePage() {
  const dispatch = useDispatch();
  const {
    employees,
    departments,
    isLoading,
    filterValues,
    positions,
    languages,
    rate,
    totalEmployees,
    domain_ids,
    industries
  } = useSelector((state) => state.filterSlice);

  const {
    backgroundColor,
  } = useSelector(state => state.settingsSlice.settings.style.main);

  const savedSmallCards = JSON.parse(localStorage.getItem('smallCards'));
  const [smallCards, setSmallCards] = useState(savedSmallCards || false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  useEffect(() => {
    localStorage.setItem('smallCards', JSON.stringify(smallCards));
  }, [smallCards]);

  const settingsFilter = useSelector(state => state.settingsSlice.settings.filter);

  useEffect(() => {
    // const { deps, pos, langs } = settingsFilter;
    const { department, position, language } = settingsFilter;
    if (department.length !== 0) {
      dispatch(handleFilterValues({
        id: 'deps',
        values: departments.filter(({ id }) => department.includes(Number(id))).map(dep => ({
          ...dep,
          label: dep.name,
          value: Number(dep.id)
        }))
      }));
      dispatch(setDepartmentIds(department));
    }
    if (position.length !== 0) {
      dispatch(handleFilterValues({
        id: 'pos',
        values: positions.filter(({ id }) => position.includes(id))
      }));
      dispatch(setPositionIds(position));
    }
    if (language.length !== 0) {
      dispatch(handleFilterValues({
        id: 'langs',
        values: languages.filter(({ id }) => language.includes(Number(id)))
      }));
      dispatch(setLangIds(language));
    }
  }, [dispatch, settingsFilter, positions, departments, languages]);

  useEffect(() => {
    const filterEmployees = (users, filterValues) => {
      const { deps, pos, skills, langs, industries } = filterValues;

      return users.filter((user) => {
        if (deps.length > 0) {
          const newDepsArr = deps.map((item) => item.value);
          const depPositions = positions.filter(({ department_id }) => newDepsArr.includes(Number(department_id))).map(i => i.name);
          const hasDepartment = depPositions.includes(user.position_name)
          if (!hasDepartment) {
            return false;
          }
        }

        if (pos.length > 0) {
          const newPosArr = pos.map((item) => item.name);
          const hasPosition = newPosArr.includes(user.position_name)

          if (!hasPosition) {
            return false;
          }
        }

        if (skills.length > 0) {
          const userSkills = user.skills.map(skill => skill.name);
          const hasAllSkills = skills.every((skill) => userSkills.includes(skill.name));
          if (!hasAllSkills) {
            return false;
          }
        }

        if (langs.length > 0) {
          const userLangs = user.languages.map(lang => lang.name);
          const hasAllLangs = langs.every((lang) => userLangs.includes(lang.name));
          if (!hasAllLangs) {
            return false;
          }
        }

        if (
            (rate === 'part' && user.sum_rate_rh < 0.49) ||
            (rate === 'full' && user.sum_rate_rh < 1)
        ) {
          return false;
        }

        if (industries.length > 0) {
          let userIndustries = []
          if (user.industries_ids !== '') {
            userIndustries = user.industries_ids.split(', ')
          }
          const hasAllIndustries = industries.every(ind => userIndustries.includes(ind.id.toString())
          );
          if (!hasAllIndustries) {
            return false;
          }
        }
        return true;
      });
    };

    const updatedFilteredEmployees = filterEmployees(employees, filterValues);
    setFilteredEmployees(updatedFilteredEmployees);

    if (
        updatedFilteredEmployees.length !== employees.length ||
        employees.length < totalEmployees
    ) {
      dispatch(setIsFiltered(true));
    } else {
      dispatch(setIsFiltered(false));
    }

  }, [dispatch, employees, positions, filterValues, rate, domain_ids, totalEmployees, settingsFilter]);

  useEffect(() => {
    const newPositions = settingsFilter.department.length !== 0
        ? positions.filter(({ department_id }) => settingsFilter.department.includes(Number(department_id)))
        : positions

    const uniquePositionsMap = filteredEmployees.map(emp => emp.position_name)
    const uniquePosValues = [...new Set(uniquePositionsMap)];
    const uniquePositionsArray = newPositions.filter(item => uniquePosValues.includes(item.name));

    const uniqueDepValues = [...new Set(newPositions.filter(item => uniquePosValues.includes(item.name)).map(i => Number(i.department_id)))];
    const uniqueDepartmentsArray = departments.filter(item => uniqueDepValues.includes(item.id))

    const uniqueSkillsMap = filteredEmployees.reduce((acc, employee) => {
      employee.skills.forEach(skill => {
        acc[skill.name] = skill;
      });
      return acc;
    }, {});
    const uniqueSkillsArray = Object.values(uniqueSkillsMap).sort((a, b) => a.name.localeCompare(b.name));

    const uniqueLangMap = filteredEmployees.reduce((acc, employee) => {
      employee.languages.forEach(lang => {
        acc[lang.name] = lang;
      });
      return acc;
    }, {});
    const uniqueLangArray = Object.values(uniqueLangMap).sort((a, b) => a.name.localeCompare(b.name));

    const uniqueIndMap = filteredEmployees.map(emp => emp.industries_ids).filter(str => str.trim() !== '')
        .map(str => str.split(', ').map(Number)).flat()
    const uniqueValues = [...new Set(uniqueIndMap)].sort((a, b) => a - b);
    const uniqueIndustriesArray = industries.filter(type => uniqueValues.includes(type.id));

    const maxRate = getMaxRate(filteredEmployees)

    dispatch(setMaxRate(maxRate));
    dispatch(setFilteredDepartments(uniqueDepartmentsArray));
    // dispatch(setFilteredPositions(uniquePositionsArray));
    dispatch(setFilteredPositions(newPositions));
    dispatch(setFilteredSkills(uniqueSkillsArray));
    dispatch(setFilteredLangs(uniqueLangArray));
    dispatch(setFilteredIndustries(uniqueIndustriesArray));
    dispatch(handleFilterTotal(filteredEmployees.length));

  }, [departments, dispatch, filteredEmployees, industries, positions, settingsFilter.department]);

  return (
      <>
        <Box
            display={"flex"}
            sx={{
              width: 'max-content',
              padding: '.5rem',
              margin: '0 auto',
            }}>
          <ToggleButtonGroup
              color="primary"
              size={"small"}
              exclusive
              value={smallCards}
              sx={{
                // color: '#fb00da',
                borderColor: 'transparent',
              }}
              onChange={(e, value) => {
                if (value !== null) {
                  setSmallCards(value);
                }
              }}>
            <ToggleButton
                value={false}
                sx={{
                  padding: '0',
                  color: getContrastColor(backgroundColor),
                  // borderColor: 'transparent',
                  // backgroundColor: 'transparent',
                  // borderColor: 'red'
                  borderColor: getContrastColor(backgroundColor),
                  opacity: '0.3'
                }}
            >
              <Tooltip title="Full view" arrow>
                <Box sx={{
                  display: 'flex',
                  padding: '0',
                }}>
                  <ViewModuleIcon sx={{
                    margin: '.5rem 1rem'
                  }}/>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
                value={true}
                sx={{
                  padding: '0',
                  color: getContrastColor(backgroundColor),
                  // borderColor: 'transparent',
                  // backgroundColor: 'transparent',
                  // borderColor: 'red'
                  borderColor: getContrastColor(backgroundColor),
                  opacity: '0.3'
                }}
            >
              <Tooltip title="Row view" arrow>
                <Box sx={{
                  display: 'flex',
                  padding: '0'
                }}>
                  <TableRowsIcon sx={{
                    margin: '.5rem 1rem'
                  }}/>
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {isLoading ? (
            <StyledSkeleton small={smallCards}/>
        ) : (
            <Box
                width={"100%"}
                display={"flex"}
                sx={{
                  // minWidth: '100vw',
                  flexFlow: "row wrap",
                  justifyContent: "space-evenly",
                  alignContent: "center",
                  gap: "1.5dvw",
                  // rowGap: "2.4dvw",
                  // alignItems: "center",
                  transition: "all .2s ease",
                  flexGrow: "1",
                  minHeight: 'calc(100vh - 6rem)',
                  padding: '1rem',
                  paddingBottom: '6rem',
                }}>

              {filteredEmployees.length > 0
                  &&
                  filteredEmployees.map((item) => (
                      <EmployeeCard
                          key={item.id}
                          person={item}
                          small={smallCards}
                          isLoading={isLoading}
                      />
                  ))
              }
            </Box>
        )
        }
      </>
  );
}

export { HomePage };
