import { Chip, Box, Popper, Fade } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { useSelector } from "react-redux";

export default function ResponsibilityItem({ responsibilityName, responsibilityDescription }) {

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const showDescriptionHandler = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const hideDescriptionHandler = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
      <Box key={responsibilityName}>
        <Chip
            label={responsibilityName}
            variant='outlined'
            size={'medium'}
            color='primary'
            onClick={() => {
            }}
            onMouseOver={showDescriptionHandler}
            onTouchStart={showDescriptionHandler}
            onMouseLeave={hideDescriptionHandler}
            onTouchEnd={hideDescriptionHandler}
            sx={{
              fontFamily: 'unset',
              fontWeight: '600',
              fontVariationSettings: `'wdth' 88`,
              textTransform: 'capitalize',
              cursor: 'pointer',
              userSelect: 'none',
            }}
        />
        <Popper
            open={open}
            anchorEl={anchorEl}
            disablePortal={false}
            keepMounted
            sx={{
              padding: '.5rem',
              width: '20rem',
            }}
            transition
            placement='top'
        >
          {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box sx={{
                  padding: `.75rem`,
                  backgroundColor: 'rgba(255,255,255,.8)',
                  backdropFilter: 'blur(1rem)',
                  overflowWrap: 'break-word',
                  border: `none`,
                  borderRadius: '.5rem',
                  color: grey[800],
                  fontVariationSettings: `'wdth' 88`,
                  boxShadow: `0 .5rem 1.8rem -.1rem rgba(0,0,0,.18)`,
                  fontSize: '.95rem'
                }}
                     dangerouslySetInnerHTML={{ __html: responsibilityDescription }}
                />
              </Fade>
          )}
        </Popper>
      </Box>
  );
}
