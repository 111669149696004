import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DynamicTheme from './theme';

export default function ThemeRegistry({ children }) {
  return (
      <ThemeProvider theme={DynamicTheme()}>
        <CssBaseline/>
        {children}
      </ThemeProvider>
  );
}
